import React, { Component } from 'react';

class HeaderMenu extends Component {

  constructor(props) {
      super(props);

      this.state = {
        values: new Map()
      };
  }

  componentDidMount() {
      this.setState({
          values: new Map([
          ["/garantiya", "Гарантия"],
          ["/dostavka", "Доставка"],
          ["/oplata", "Оплата"],
          //["/review", "Отзывы"],
          ["/sotrudnichestvo", "Сотрудничество"],
          ["/stock", "Акции"],
          ["/about", "О нас"],
          ["/news", "Новости"],
          ["/howToBuy", "Как купить?"],
          ["/dealer", "Стать дилером"],
        ])
      })
  }

  render() {
    return (
      <div className="header-menu">
          <nav>

              <ul className="menu">

                {Array.from(this.state.values.entries()).map(([key, value]) => (
              
                  <li key={key}><a className="" href={key} style={{minWidth : 64, maxWidth: 150}}>{value}</a></li>
                
                ))}
              
              </ul>

          </nav>
      </div>
    )
  }
}

export default HeaderMenu;