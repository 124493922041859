import React, { Component } from 'react';

import ModalText from './../../modal/ModalText';

class ColumnRight extends Component {

    getYear() {
        return `${new Date().getFullYear()}`;
    }

    render() {
        return (
            <div className="footer-menu-col">
                <nav>
                    <ul className="menu">
                        <li style={{ color: "#FFF" }}>
                            <ModalText title={"Политика конфиденциальности"} fileName="/PrivacyPolicy.txt"></ModalText></li>
                        <li style={{ color: "#FFF" }}>
                            <ModalText title={"Публичная оферта"} fileName="/PublicOffer.txt"></ModalText></li>
                        <li style={{ color: "#FFF" }}>Все права защищены&nbsp;{this.getYear()}</li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default ColumnRight;