import React, { Component } from 'react';

class SelectionCars extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values1: new Map(),
            values2: new Map(),
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            values1: new Map([
                ["chevrolet.png", "chevrolet"],
                ["daewoo.png", "daewoo"],
                ["honda.png", "honda"],
                ["hyundai.png", "hyundai"],
                ["kia.png", "kia"],
                ["mazda.png", "mazda"],
                ["mitsubishi.png", "mitsubishi"],
                ["nissan.png", "nissan"],
                ["ssangyong.png", "ssangyong"],
                ["subaru.png", "subaru"],
                ["toyota.png", "toyota"],
                ["volkswagen.png", "volkswagen"],
            ]),
            values2: new Map([
            ]),
        })
    }

    handleButtonClick(manufacture) {
        const value = {
            year: 0,
            value: "",
            model: "",
            manufacture: manufacture.toUpperCase(),
        };

        const search = {
            value
        };
    
        window.location.href = `./catalog?search=${encodeURIComponent(JSON.stringify(search))}`;
    }
    
    
    render() {
        const { values1, values2 } = this.state;

        return (
            <div className="section-home-banner" style={{ background: "#FFF", paddingTop: 20 }}>
                <div className="container" style={{ marginTop: 50 }}>

                    <div className="section-top__swiper swiper-container-initialized swiper-container-horizontal">
                        <div className="row swiper-wrapper" style={{ transform: "translate3d(0px, 0px, 0px)" }}>

                            {Array.from(values1.entries()).map(([key, value]) => (

                                <div key={key} className="swiper-slide col-lg-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 10, margin: "auto", position: "relative" }}>
                                    <button className="car-btn" onClick={() => this.handleButtonClick(value)}>
                                        <img style={{ margin: 10, background: "#FFF", width: 100, height: 100, borderColor: "#000"}} src={"./images/cars/" + key} alt={value} />
                                    </button>
                                </div>

                            ))}

                            {Array.from(values2.entries()).map(([key, value]) => (

                                <div key={key} className="swiper-slide col-lg-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "auto", margin: "auto", position: "relative" }}>
                                    <button className="car-btn" onClick={() => this.handleButtonClick(value)}>
                                        <img style={{ margin: 10, background: "#FFF", width: 100, height: 100, borderColor: "#000"}} src={"./images/cars/" + key} alt={value} />
                                    </button>
                                </div>

                            ))}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectionCars;