import React from 'react'

export default function SectionAdvantages() {
    return (
        <div className="section section-advantages">
            <div className="section-line">
                <svg width="2000" height="87" viewBox="0 0 2000 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2000 44.5189L1696.96 44.5188L1622.29 2.00028L1280.96 2.00022L1142.29 84.9632L438.16 84.963L309.28 14.573L-1.93027e-06 14.573" stroke="#0575C0" strokeWidth="3" strokeMiterlimit="10"></path>
                </svg>
            </div>
            <div className="section-line-bg section-line-bg-top">
                <svg width="2000" height="93" viewBox="0 0 2000 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M301.199 15L-2.62267e-06 14.9999L1.37405e-05 92.9995L2000 93L2000 36.0001L1685.25 36.0001L1619.59 0.000283179L1292.34 0.00022596L1146.43 81.9997L422.095 81.9996L301.199 15Z" fill="#333333"></path>
                </svg>
            </div>

            <div className="container">

                <div className="row">
                    <div className="col-lg-10 offset-lg-1">

                        <div className="section-title">
                            <div className="section-title-bg">
                                <img src="./images/advantage-bg-title.png" alt=""/>
                            </div>
                            <em>Наши</em><br/><br/><br/>
                            преимущества
                        </div>

                    </div>
                </div>

                <div className="advantages__row">
                    <div className="row">

                        <div className="col-lg-5 offset-lg-1 advantage-col">
                            <div className="advantage-item">
                                <div className="advantage-item__icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M40.5063 56C37.653 51.8133 34.6663 46.2933 34.6663 41.3333C34.6663 36.4533 37.2263 32 41.0663 29.3333H39.9997V24H45.333V27.28C46.6663 26.9067 47.9997 26.6667 49.333 26.6667C49.7863 26.6667 50.2397 26.6667 50.6663 26.7467V8H13.333V56H29.333V46.6667H34.6663V56H40.5063ZM39.9997 13.3333H45.333V18.6667H39.9997V13.3333ZM23.9997 50.6667H18.6663V45.3333H23.9997V50.6667ZM23.9997 40H18.6663V34.6667H23.9997V40ZM23.9997 29.3333H18.6663V24H23.9997V29.3333ZM23.9997 18.6667H18.6663V13.3333H23.9997V18.6667ZM29.333 13.3333H34.6663V18.6667H29.333V13.3333ZM29.333 24H34.6663V29.3333H29.333V24ZM29.333 40V34.6667H34.6663V40H29.333ZM49.333 32C44.2663 32 39.9997 36.2933 39.9997 41.3333C39.9997 48.2933 49.333 58.6667 49.333 58.6667C49.333 58.6667 58.6663 48.2933 58.6663 41.3333C58.6663 36.2933 54.3997 32 49.333 32ZM49.333 44.8267C47.4663 44.8267 46.133 43.2267 46.133 41.6267C46.133 39.76 47.733 38.4267 49.333 38.4267C50.933 38.4267 52.533 40 52.533 41.6267C52.7997 43.2267 51.1997 44.8267 49.333 44.8267Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div className="advantage-item__text">
                                    Прямые поставки<br/><br/>
                                    с завода изготовителя
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 advantage-col">
                            <div className="advantage-item">
                                <div className="advantage-item__icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.6667 8H13.3333C10.3733 8 8 10.3733 8 13.3333V50.6667C8 53.6267 10.4 56 13.3333 56H50.6667C53.6267 56 56 53.6267 56 50.6667V13.3333C56 10.3733 53.6 8 50.6667 8ZM23.5467 18.8C26.16 18.8 28.2667 20.9067 28.2667 23.5467C28.2667 26.16 26.16 28.2667 23.5467 28.2667C20.9067 28.2667 18.8 26.16 18.8 23.5467C18.8 20.9067 20.9067 18.8 23.5467 18.8ZM40.5867 45.3333C37.9733 45.3333 35.8667 43.2 35.8667 40.5867C35.8667 37.9733 37.9733 35.8667 40.5867 35.8667C43.2 35.8667 45.3333 37.9733 45.3333 40.5867C45.3333 43.2 43.2 45.3333 40.5867 45.3333ZM22.6667 45.4133L18.6667 41.4133L41.4133 18.6667L45.4133 22.6667L22.6667 45.4133Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div className="advantage-item__text">
                                    Скидки для<br/><br/>
                                    постоянных клиентов
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 offset-lg-1 advantage-col">
                            <div className="advantage-item">
                                <div className="advantage-item__icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6663 8C7.70634 8 5.33301 10.3733 5.33301 13.3333V40C5.33301 41.4145 5.89491 42.771 6.8951 43.7712C7.8953 44.7714 9.25185 45.3333 10.6663 45.3333H31.9997V58.6667L39.9997 50.6667L47.9997 58.6667V45.3333H53.333C54.7475 45.3333 56.104 44.7714 57.1042 43.7712C58.1044 42.771 58.6663 41.4145 58.6663 40V21.3333V16V13.3333C58.6663 11.9188 58.1044 10.5623 57.1042 9.5621C56.104 8.5619 54.7475 8 53.333 8H42.6663H10.6663ZM31.9997 13.3333L39.9997 18.6667L47.9997 13.3333V22.6667L55.9997 26.6667L47.9997 30.6667V40L39.9997 34.6667L31.9997 40V30.6667L23.9997 26.6667L31.9997 22.6667V13.3333ZM10.6663 13.3333H23.9997V18.6667H10.6663V13.3333ZM10.6663 24H18.6663V29.3333H10.6663V24ZM10.6663 34.6667H23.9997V40H10.6663V34.6667Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div className="advantage-item__text">
                                    Гарантия качества<br/><br/>
                                    всех товаров
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 advantage-col">
                            <div className="advantage-item">
                                <div className="advantage-item__icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M45.3333 8H18.6667C12.7733 8 8 12.7733 8 18.6667V45.3333C8 51.2267 12.7733 56 18.6667 56H50.6667C53.6267 56 56 53.6267 56 50.6667V24C56 21.0667 53.6267 18.6667 50.6667 18.6667V13.3333C50.6667 10.4 48.2933 8 45.3333 8ZM45.3333 13.3333V18.6667H18.6667C16.72 18.6667 14.9067 19.2 13.3333 20.1067V18.6667C13.3333 15.7333 15.7333 13.3333 18.6667 13.3333M41.3333 41.3333C39.12 41.3333 37.3333 39.5467 37.3333 37.3333C37.3333 35.12 39.12 33.3333 41.3333 33.3333C43.5467 33.3333 45.3333 35.12 45.3333 37.3333C45.3333 39.5467 43.5467 41.3333 41.3333 41.3333Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div className="advantage-item__text">
                                    Удобные<br/><br/>
                                    способы оплаты
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div className="section-line-bg section-line-bg-bottom">
                <svg width="2000" height="60" viewBox="0 0 2000 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1475.22 24.0004L2000 24.0004L2000 0.00025177L0.0102487 0.000251139L-3.14723e-06 24.0004L314.59 24.0003L380.216 60.0003L1388.52 60.0003L1475.22 24.0004Z" fill="#333333"></path>
                </svg>
            </div>
        </div>
    )
}
