import React, { Component } from 'react';

import Breadcrumb from '../header/Breadcrumb'

class NotFoundPage extends Component {

    render() {
        return (
            <div className="page-content" style={{ padding: 140, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Запрашиваемая страница не найдена!"],
                    ])} />

                    <div>
                        <div className="page-content__title section-title">
                            Запрашиваемая страница не найдена!
                        </div>
                        <div className="page-content__desc section-desc">
                            <p>К сожалению, запрашиваемая Вами страница не найдена.</p>
                            <p>Вероятно, Вы указали несуществующий адрес, страница была удалена, перемещена или сейчас она временно недоступна!</p>
                        </div>

                        <div className="buttons clearfix">
                            <div className="pull-right">
                                <a href="/" className="btn btn-primary">Продолжить</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default NotFoundPage;
