import React, { useEffect } from 'react';

const Map = () => {
    useEffect(() => {
        const loadScript = () => {
            return new Promise((resolve, reject) => {
                const existingScript = document.querySelector('script[src="https://maps.api.2gis.ru/2.0/loader.js?pkg=full"]');
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.src = "https://maps.api.2gis.ru/2.0/loader.js?pkg=full";
                    script.async = true;
                    script.onload = () => resolve(window.DG);
                    script.onerror = reject;
                    document.body.appendChild(script);
                } else {
                    resolve(window.DG);
                }
            });
        };

        const initMap = () => {
            const interval = setInterval(() => {
                if (window.DG && window.DG.map) {
                    clearInterval(interval);
                    const map = window.DG.map('map', {
                        center: [43.243969, 76.890718],
                        zoom: 13
                    });

                    window.DG.marker([43.243986, 76.890615]).addTo(map).bindPopup('feparts');
                    window.DG.marker([43.261663, 76.892755]).addTo(map).bindPopup('feparts');
                    window.DG.marker([43.261638, 76.856925]).addTo(map).bindPopup('feparts');
                }
            }, 100);
        };

        loadScript()
            .then(initMap)
            .catch((error) => {
                console.error('Failed to load the DG script:', error);
            });

        return () => {
            const script = document.querySelector('script[src="https://maps.api.2gis.ru/2.0/loader.js?pkg=full"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return (
        <div id="map" style={{ width: '100%', height: '450px' }}></div>
    );
};

export default Map;
