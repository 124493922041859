import React, { Component } from 'react';


export const formatAmount = (amount) => {
    return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '0';
};

class Format extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value ) {
            this.setState({
                value: this.props.value,
            });
        }
    }
    
    render() {
        const {value} = this.state;

        return (
            <>
            {formatAmount(value)}
            </>
        )
    }
}

export default Format;