import React, { Component } from 'react';

import Breadcrumb from '../header/Breadcrumb'

class Error extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: ""
        };
    }
    
    async componentDidMount() {
        const { error } = this.props.location.state;

        this.setState({error: error});
    }

    render() {
        const { error } = this.state;

        return (
            <div className="page-content" style={{ padding: 140, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Ошибка!"],
                    ])} />

                    <div>
                        <div className="page-content__title section-title">
                            Ошибка!
                        </div>
                        <div className="page-content__desc section-desc">
                            <p>
                                <span>{ error }</span>
                            </p>
                            <p>
                                Пожалуйста<a href="/contact">&nbsp;свяжитесь с нами</a>.
                            </p>
                        </div>

                        <div className="buttons clearfix">
                            <div className="pull-right">
                                <a href="/" className="btn btn-primary">На главную страницу</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Error;
