import React, { Component } from 'react';

import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Token from './../utils/Token';
import Telphone from './../utils/Telphone';

class RequestCall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tel: '',
            name: '',
            message: '',
            modalIsOpen: true,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleRequestCall = this.handleRequestCall.bind(this);
    }

    closeModal() {
        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    async getUserData() {
        try {
            const response = await fetch('/api/user/' + (new Token()).get(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            console.log(result);

            this.setState({ 
                tel: result.tel || '', 
                name: result.fullName ||' '
            });
        } catch (error) {
            console.error('Failed to fetch request:', error);
        }
    }

    componentDidMount() {
        this.getUserData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tel !== this.props.tel) {
            this.setState({ tel: this.props.tel });
        }
        if (prevProps.name !== this.props.name) {
            this.setState({ name: this.props.name });
        }
        if (prevProps.message !== this.props.message) {
            this.setState({ message: this.props.message });
        }
    }

    async handleRequestCall() {
        const { tel, name } = this.state;

        try {
            const response = await fetch('/api/requestCall', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tel, name }),
            });

            if (response.ok) {
                const result = await response.json();

                this.setState({ message: result.message });

                if (result.value) {
                    setTimeout(() => {
                        this.setState({ modalIsOpen: false }, () => {
                            this.closeModal();
                        });
                    }, 5000);
                }
            }
        } catch (error) {
            console.error('Failed to fetch request:', error);
        }
    }

    render() {
        const { tel, name, message, modalIsOpen } = this.state;

        return (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={this.closeModal}
                style={this.customStyles}
            >
                <div className="modal-content" style={{ border: 0 }}>
                    <div className="form__block">
                        <div className="modal__form--top">
                            <div className="form__block--title">Заказ звонка</div>
                        </div>

                        <div className="form__block--form">
                            <div className="row align-items-center">
                                {message.length === 0 ? (
                                    <>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                className="input-linck"
                                                value={name}
                                                placeholder="Ваше имя"
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Telphone tel={tel} onChange={(e) => this.setState({ tel: e.target.value })} />
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="popup__linck--block">
                                                <button className="button-popup button-popup--shadow" onClick={this.handleRequestCall}>
                                                    Заказать звонок
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='col'>
                                        <div style={{textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: message }}></div>
                                        <div className="col-sm-12">
                                            <div className="popup__linck--block">
                                                <button className="button-popup button-popup--shadow" onClick={this.closeModal}>
                                                    OK
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '50%',
            maxWidth: 300,
            transform: 'translate(-50%, -50%)',
        },
    };
}

RequestCall.propTypes = {
    tel: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
};

export default RequestCall;
