import React, { Component } from 'react';

import moment from 'moment';

class ItemNews extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            img: this.props.img,
            date: this.props.date,
            text: this.props.text,
            title: this.props.title,
        };
    }

    render() {
        const {id, img, date, text, title} = this.state;

        return (
            <div className="col-lg-4  col-md-6 col-sm-6 news-item__col">
            <div className="news-item">
                <div className="news-item__img">
                    <a href={"news/" + id}>
                        <img src={"./images/news/" + img} alt="" title="" />
                    </a>
                </div>
                <div className="news-item__content">
                    <div className="news-item__date">
                        <p>{moment(date).format('d.MM.YYYY')}</p>
                    </div>
                    <div className="news-item__title">
                        <p>{title}</p>
                    </div>
                    <div className="news-item__desc">
                        <p>{text}</p>
                    </div>
                </div>
                <a href={"news/" + id} className="news-item__link">Подробнее</a>
            </div>
        </div>
        )
    }
}

export default ItemNews;