import React, { Component } from 'react';

import Loader from 'react-loader';

import Breadcrumb from './../../header/Breadcrumb';
import ModalImage from './../../modal/ModalImage';
import Format from './../../utils/Format';
import Token from './../../utils/Token';
import User from './../../utils/User';
import UserType from './../../enum/UserType';
import EventBus from './../../utils/EventBus';
import ToastNotification from '../../utils/ToastNotification';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: {},
            count: 1,
            loaded: false,
            showImage: false,
            imageCount: 0,
            countCart: 0,
            selectedIndex: null,
            showToast: false,
        };

        this.edit = this.edit.bind(this);
        this.plus = this.plus.bind(this);
        this.minus = this.minus.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        try {
            const response = await fetch(`/api/product/${id}?token=${(new Token()).get()}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const product = await response.json();
            this.setState({ product, loaded: true }, this.checkImage);
            this.getCountOnCart();

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.product !== this.props.product) {
            this.setState({ product: this.props.product }, this.checkImage);
        this.getCountOnCart();
        }
    }

    async getCountOnCart() {
        const { product } = this.state;
        try {
            const response = await fetch(`/api/cart/product/count/${product.uuid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                }
            });

            if (response.ok) {
                const result = await response.json();

                this.setState({ countCart: result.count });
            }
        } catch (error) {
            this.setState({ countCart: 0 });
        }
    }

    handleImageClick = (index) => {
        this.setState({ selectedIndex: index, showImage: true });
    }

    renderStars() {
        const { product } = this.state;
        let stars = [];

        for (let i = 0; i < product.rating; i++) {
            stars.push(<React.Fragment key={i}>{'\u00A0'}<img src={`./../images/rating/star_on.png`} alt="star" /></React.Fragment>);
        }

        if (product.rating < 5) {
            for (let i = 0; i < (5 - product.rating); i++) {
                stars.push(<React.Fragment key={i + product.rating}>{'\u00A0'}<img src={`./../images/rating/star_off.png`} alt="star" /></React.Fragment>);
            }
        }

        return stars;
    }

    plus() {
        const { count, product } = this.state;

        this.setState({
            count: Math.min(count + 1, product.count),
        });
    }

    minus() {
        const { count } = this.state;

        this.setState({
            count: Math.max(count - 1, 1),
        });
    }

    edit(e) {
        const value = parseInt(e.target.value, 10) || 1;
        const { product } = this.state;

        this.setState({
            count: Math.max(1, Math.min(value, product.count)),
        });
    }

    setHideToast() {
        this.setState({ showToast: false });
    }

    async handleAddToCart() {
        const { count, product } = this.state;

        try {
            const response = await fetch('/api/cart/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify({ uuid: product.uuid, count: count, amount: (count * product.price) })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.status == "OK") {
                this.setState({ showToast: true });
                EventBus.emit('cartUpdated', true);
                this.getCountOnCart();
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    render() {
        const { product, loaded, showImage, imageCount, selectedIndex, count, showToast, countCart } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <Loader loaded={loaded}>
                    <div className="container">
                        <Breadcrumb values={new Map([
                            ["/", "Главная"],
                            ["/catalog", "Каталог"],
                            ["/#", product.name],
                        ])} />

                        <div>
                            <div className="row">
                                <div className="col-lg-6">

                                    {countCart > 0 &&
                                        <div>
                                            <div className="header-card">
                                                <i className="header-icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 6H17C17 3.2 14.8 1 12 1C9.2 1 7 3.2 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.7 3 15 4.3 15 6H9C9 4.3 10.3 3 12 3ZM19 20H5V8H19V20ZM12 12C10.3 12 9 10.7 9 9H7C7 11.8 9.2 14 12 14C14.8 14 17 11.8 17 9H15C15 10.7 13.7 12 12 12Z" fill="#000"></path>
                                                    </svg>
                                                    <span className="card-number">{countCart}</span>
                                                </i>
                                            </div>
                                        </div>
                                    }

                                    <div className="product-item__image">
                                        <div>
                                            <img src={`/api/image/1/${product.uuid}`} title={product.name} alt={product.name} />
                                            {product.price !== product.oldPrice && new User().getType() != UserType.WHOLESALE && (
                                                <img className="sale-icon" style={{ width: 120, height: 90, objectFit: "none", right: 100 }} src="./../images/sale.png" alt="Sale" />
                                            )}
                                            {showImage && selectedIndex === 1 && (
                                                <ModalImage value={product} index={1} count={imageCount} onClose={() => this.setState({ showImage: false })} />
                                            )}
                                        </div>
                                    </div>
                                    {!product.noimage && imageCount > 1 && (
                                        <div className="product-item__image">
                                            {Array.from({ length: imageCount }, (_, i) => (
                                                <React.Fragment key={i}>
                                                    <img
                                                        style={{ cursor: "pointer", width: 100, height: 100 }}
                                                        src={`./../images/product/${product.uuid}/${i + 1}.png`}
                                                        title={product.name}
                                                        alt={product.name}
                                                        onClick={() => this.handleImageClick(i + 1)}
                                                    />
                                                    {showImage && selectedIndex === i + 1 && (
                                                        <ModalImage
                                                            value={product}
                                                            index={i + 1}
                                                            count={imageCount}
                                                            onClose={() => this.setState({ showImage: false })}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6">
                                    <div className="product__right">
                                        <h1>{product.name}</h1>
                                        {product.brand && (
                                            <h3 className="product__model">Бренд:<span style={{paddingLeft: 5}}>{product.brand.toUpperCase()}</span></h3>

                                        )}
                                        <h3 className="product__model">Номер производителя:<span style={{paddingLeft: 5}}>{product.original}</span></h3>
                                        <h3 className="product__model">
                                            {product.count > 0 ? (
                                                <>
                                                    <span>Количество:</span>
                                                    {product.count > 10 ? (
                                                        <span className="text_stock" style={{ paddingLeft: 5, fontWeight: "bold", color: "green" }}>
                                                            10+
                                                        </span>
                                                    ) : (
                                                        <span className="text_stock" style={{ paddingLeft: 5,fontWeight: "bold", color: "green" }}>
                                                            {product.count}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <span className="text_stock" style={{ fontWeight: "bold", color: "red" }}>
                                                    Нет в наличии
                                                </span>
                                            )}
                                        </h3>

                                        <h3 className="product__model">
                                            <span>Рейтинг:</span>
                                            <span style={{ paddingLeft: 5, position: "relative", top: -3 }}>
                                                {this.renderStars()}
                                            </span>
                                        </h3>
                                        {product.count > 0 && product.price > 0 ? (
                                            <div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className="product-item__input product-counter">

                                                            <button className="product-item__input-btn minus" type="button" onClick={this.minus}>
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M25.3337 17.3334H6.66699V14.6667H25.3337V17.3334Z" fill="#333333"></path>
                                                                </svg>
                                                            </button>

                                                            <input className="product-item__input-control" type="text" value={count} onChange={this.edit} />

                                                            <button className="product-item__input-btn plus" type="button" onClick={this.plus}>
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M25.3337 17.3334H17.3337V25.3334H14.667V17.3334H6.66699V14.6667H14.667V6.66675H17.3337V14.6667H25.3337V17.3334Z" fill="#333333"></path>
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="product-item__prices">
                                                            <div className="product-item__new-price">
                                                                <Format value={product.price} /><span style={{ marginTop: 5 }}>₸</span>
                                                            </div>
                                                            {product.price !== product.oldPrice && (
                                                                <div className="product-item__old-price">
                                                                    <Format value={product.oldPrice} /><span style={{ marginTop: 5 }}>₸</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="product-item__button">
                                                    <button type="button" className="goods__buy-btn md-trigger" onClick={this.handleAddToCart}>Добавить в корзину</button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="product-item__content--footer goods__buy">
                                                <div className="product-item__prices">
                                                    <div className="product-item__new-price">
                                                        <Format value={product.price} /><span style={{ marginTop: 5 }}>₸</span>
                                                    </div>
                                                    {product.price !== product.oldPrice && (
                                                        <div className="product-item__old-price">
                                                            <Format value={product.oldPrice} /><span style={{ marginTop: 5 }}>₸</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="product__content">
                                <div className="product-content__tabs">{product.desc}</div>
                                {product.numbers && Array.isArray(product.numbers) && product.numbers.length > 0 && (
                                    <div className="product-content__tab-content">
                                        <div className="product-content__tab-item active">
                                            <div className="cross-numbers">
                                                <h3 className="product__model">Кросс-номера:</h3>
                                                <ul>
                                                    {product.numbers.map((number, index) => (
                                                        <li key={index}>{number}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="product__content">
                                {product.card && Array.isArray(product.card) && product.card.length > 0 && (
                                    <div className="product-content__tab-content">
                                        <div className="product-content__tab-item active">
                                            <div className="cross-numbers">
                                                <h3 className="product__model">Машины:</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ borderTop: "1px solid #000000", borderBottom: "1px solid #000000" }}>Производитель</th>
                                                            <th scope="col" style={{ textAlign: 'center', borderTop: "1px solid #000000", borderBottom: "1px solid #000000" }}>Марка</th>
                                                            <th scope="col" style={{ textAlign: 'right', borderTop: "1px solid #000000", borderBottom: "1px solid #000000" }}>Годы выпуска</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product.card.map((car, index) => (
                                                            <tr key={index}>
                                                                <td style={{ borderBottom: "1px solid #000000" }}>{car.manufacturer}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: "1px solid #000000" }}>{car.model}</td>
                                                                <td style={{ textAlign: 'right', borderBottom: "1px solid #000000" }}>{car.years[0]} - {car.years[car.years.length - 1]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Loader>
                {showToast && <ToastNotification text={`Добавлено в корзину - ${product.name}, количество - ${count}`} onClose={() => this.setHideToast()} />}
            </div>
        );
    }
}

export default Product;
