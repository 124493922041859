import React, { Component } from 'react';

import RequestCall from './../../modal/RequestCall';

class HeaderRing extends Component {

    constructor(props) {
        super(props);

        this.state = {
          show: false,
        };
    }

    openModal = () => {
      this.setState({ show: true });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value ) {
            this.setState({
              show: this.props.show,
            });
        }
    }
    
    render() {
        const { show } = this.state;

        return (
          <div className="header-popup">
            <button className="btn btn-primary" style={{borderRadius: 3}} onClick={this.openModal}>Заказать&nbsp;звонок</button>
            {show && <RequestCall onClose={() => this.setState({ show: false })} />}
          </div>
        )
    }
}

export default HeaderRing;