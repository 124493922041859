import React, { Component } from 'react';

class Mobile extends Component {

    style = {
        margin: 0,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }

    render() {
        return (
            <div style={ this.style }>
                <h1>
                    Use Mobile Application
                </h1>
            </div>
           )
    }
}

export default Mobile;
