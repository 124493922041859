import React, { Component } from 'react';

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";
import Breadcrumb from "./../../header/Breadcrumb";
import ModalWindow from "./../../modal/ModalWindow";
import ClientType from "./../../enum/ClientType";

class Wholesale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            read: false,
            show: false,
            message: "",
            redirect: true,
            modalIsOpen: false,
            data: {
                tel: "",
                email: "",
                confirm: "",
                fullName: "",
                password: "",
                newsletter: true,
                requisites: [{ type: ClientType.LEGAL, company: '', bin: '', address: '' }]
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    handleCheckClick = () => {
        this.setState((prevState) => ({ read: !prevState.read }));
    };

    handleSend = async (event) => {
        event.preventDefault();
        if (this.checkData()) {
            if (await this.checkEmail() && await this.checkTel()) {
                try {
                    const response = await fetch('/api/user/wholesale/create', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(this.state.data)
                    });

                    const result = await response.json();
                    this.setState({ message: result.message, show: true, redirect: result.value });

                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            }
        }
    };

    handleChange = (key, value) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [key]: value
            }
        }));
    };

    handleRequisiteChange = (index, key, value) => {
        const newRequisites = [...this.state.data.requisites];
        newRequisites[index][key] = value;
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: newRequisites
            }
        }));
    };

    addRequisite = () => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: [...prevState.data.requisites, { type: ClientType.LEGAL, company: '', bin: '', address: '' }]
            }
        }));
    };

    removeRequisite = (index) => {
        const newRequisites = this.state.data.requisites.filter((_, i) => i !== index);
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: newRequisites
            }
        }));
    };

    handleBinChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            this.handleRequisiteChange(index, 'bin', value);
        }
    }

    checkEmail = async () => {
        let res = true;

        const { data } = this.state;

        try {
            const response = await fetch(`/api/check/email/${data.email}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            const result = await response.json();
           
            if (!result.value) {
                this.setState({ show: true, message: result.message, redirect: false });
                res = false;
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            res = false;
        }

        return res;
    };

    checkTel = async () => {
        let res = true;

        const { data } = this.state;

        try {
            const response = await fetch(`/api/check/tel/${data.tel}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            const result = await response.json();
           
            if (!result.value) {
                this.setState({ show: true, message: result.message, redirect: false });
                res = false;
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            res = false;
        }

        return res;
    };

    checkData() {
        let result = true; 

        const {data} = this.state;

        if (data.email == "") {
            this.setState({ show: true, message: "Заполните email", redirect: false });

            result = false;
        }

        const pattern = /^[a-zA-Z0-9._%+-]{1,100}@[a-zA-Z0-9.-]{1,100}\.[a-zA-Z]{1,10}$/;
        
        if (!pattern.test(data.email)) {
            this.setState({ show: true, message: "Введен некоректный email!", redirect: false });

            result = false;
        }

        if (data.tel == "") {
            this.setState({ show: true, message: "Заполните номер телефона", redirect: false });

            result = false;
        }

        if (data.fullName == "") {
            this.setState({ show: true, message: "Заполните Ф. И. О.", redirect: false });

            result = false;
        }

        if (data.password == "") {
            this.setState({ show: true, message: "Заполните пароль", redirect: false });

            result = false;
        }

        if (data.confirm == "") {
            this.setState({ show: true, message: "Повторите пароль", redirect: false });

            result = false;
        }

        if (data.password != data.confirm) {
            this.setState({ show: true, message: "Введеные пароли не совпадают", redirect: false });

            result = false;
        }

        if (data.requisites.length == 0) {
            this.setState({ show: true, message: "Введите реквизиты", redirect: false });

            result = false;
        }

        if (data.requisites.length > 0) {
            data.requisites.forEach((item, index) => {
                if(item.type == ClientType.LEGAL) {
                    if (item.company === "") {
                        this.setState({ show: true, message: "Введите наименование компании в " + (index + 1) + " строке", redirect: false });

                        result = false;
                    }
                    
                    if (item.bin === "") {
                        this.setState({ show: true, message: "Введите БИН/ИИН в " + (index + 1) + " строке", redirect: false });

                        result = false;
                    }
                }
                if(item.type == ClientType.INDIVIDUAL) {
                    if (item.address === "") {
                        this.setState({ show: true, message: "Введите адрес доставки в " + (index + 1) + " строке", redirect: false });

                        result = false;
                    }
                }
            });
        }

        return result;
    }

    render() {
        const { data, show, message, redirect } = this.state;

        return (
            <div className="page-content container" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Регистрация (Оптовый клиент)"],
                ])} />

                <div className="row">
                    <div className="col-sm-12">
                        <h1>Регистрация</h1>
                        <p>Если Вы уже зарегистрированы, перейдите на страницу <a href="/login">авторизации</a>.</p>
                        <form onSubmit={this.handleSend}>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <div className='row'>
                                    <div className="col-12">
                                        <label className="label" htmlFor="input-firstname"><span style={{ color: "red" }}>*</span>&nbsp;<b>Ф. И. О.</b></label>
                                        <input type="text" value={data.fullName} placeholder="Ф. И. О." className="form-control" onChange={(e) => this.handleChange('fullName', e.target.value)} />
                                    </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className="col-6">
                                        <label className="label" htmlFor="input-email"><span style={{ color: "red" }}>*</span>&nbsp;<b>email</b></label>
                                        <CheckEmail currentEmail={data.email} className="form-control" onChange={(e) => this.handleChange('email', e.target.value)} />
                                    </div>
                                    <div className="col-6">
                                        <label className="label" htmlFor="input-telephone"><span style={{ color: "red" }}>*</span>&nbsp;<b>Телефон</b></label>
                                        <Telphone tel={data.tel} onChange={(e) => this.handleChange('tel', e.target.value)} />
                                    </div>
                                </div>
                            </fieldset>
                            <br />
                            <fieldset>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <legend>Реквизиты</legend>
                                    </div>
                                    <div>
                                        <button type="button" className='btn btn-primary' onClick={this.addRequisite}>Добавить реквизиты</button>
                                    </div>
                                </div>
                                    {data.requisites && data.requisites.map((item, index) => {
                                        const { type, company, bin, address } = item;

                                        return (
                                            <>
                                                <div className='row'>
                                                    <div className='col-2' style={{height: 40}}>
                                                        <select onChange={(e) => this.handleRequisiteChange(index, 'type', e.target.value)} style={{width: "100%", margin: 10, height: 40}}>
                                                            <option value={ClientType.LEGAL}>Юр. лицо</option>
                                                            <option value={ClientType.INDIVIDUAL}>Физ. лицо</option>
                                                        </select>
                                                    </div>
                                                    {type === ClientType.LEGAL && (
                                                        <>
                                                            <div className='col-2'>
                                                                <input style={{position: 'absolute', top: 10, width: "99%", height: 40}}
                                                                    type="text"
                                                                    value={item.company}
                                                                    title="Наименование компании"
                                                                    placeholder="Наименование компании"
                                                                    
                                                                    onChange={(e) => this.handleRequisiteChange(index, 'company', e.target.value)}
                                                                />
                                                            </div>
                                                            <div className='col-2'>
                                                                <input style={{position: 'absolute', top: 10, width: "99%", height: 40}}
                                                                    type="text"
                                                                    value={item.bin}
                                                                    title="БИН/ИИН"
                                                                    placeholder="БИН/ИИН"
                                                                    
                                                                    onChange={(e) => this.handleBinChange(index, e.target.value)}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className={type === ClientType.LEGAL ? "col-5" : "col-9"}>
                                                        <input  style={{position: 'absolute', top: 10, width: "100%", height: 40}}
                                                            type="text"
                                                            value={item.address}
                                                            title="Адрес доставки"
                                                            placeholder="Адрес доставки"
                                                            
                                                            onChange={(e) => this.handleRequisiteChange(index, 'address', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-1 text-rigth'>
                                                        <span style={{position: 'absolute', top: 20, height: 40}}>
                                                            <img
                                                                src="./../../images/icons/remove.png"
                                                                style={{ cursor: 'pointer', marginLeft: 20, width: 20, height: 20 }}
                                                                onClick={() => this.removeRequisite(index)}
                                                                alt="delete"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <br/>
                                            </>
                                        );
                                    })}
                            </fieldset>
                            <fieldset>
                                <legend>Ваш пароль</legend>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Пароль</b></label>
                                        <input type="password" value={data.password} placeholder="Пароль" className="form-control" onChange={(e) => this.handleChange('password', e.target.value)} />
                                    </div>
                                    <div className="col-6">
                                        <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Подтверждение пароля</b></label>
                                        <input type="password" value={data.confirm} placeholder="Подтверждение пароля" className="form-control" onChange={(e) => this.handleChange('confirm', e.target.value)} />
                                    </div>
                                </div>
                            </fieldset>
                            <br />
                            <fieldset>
                                <legend>Рассылка новостей</legend>
                                <div className="form-group">
                                    <label className="col-sm-12 control-label">Я хочу получать рассылку об акциях и спецпредложениях</label>
                                    <div className="col-sm-10">
                                        <label className="radio-inline">
                                            <input type="radio" checked={data.newsletter === true} onChange={() => this.handleChange('newsletter', true)} />
                                            &nbsp;Да
                                        </label>
                                        &nbsp;
                                        &nbsp;
                                        <label className="radio-inline">
                                            <input type="radio" checked={data.newsletter === false} onChange={() => this.handleChange('newsletter', false)} />
                                            &nbsp;Нет
                                        </label>
                                    </div>
                                </div>
                            </fieldset>

                            <div className="buttons">
                                <div className="pull-right">
                                    <input type="checkbox" onClick={this.handleCheckClick} />
                                    &nbsp;Я&nbsp;прочитал&nbsp;
                                    <a href="/information" className="agree">
                                        <b>
                                            политику&nbsp;безопасности
                                        </b>
                                    </a>&nbsp;и&nbsp;согласен&nbsp;с&nbsp;условиями&nbsp;безопасности&nbsp;и&nbsp;обработки&nbsp;персональных&nbsp;данных&nbsp;<br /><br />
                                </div>
                                <button type="submit" style={{ display: "block!important" }} disabled={!this.state.read} className="btn btn-primary">Продолжить</button>
                                {show && <ModalWindow value={message} redirect={redirect} redirectUrl={"/"} onClose={() => this.setState({ show: false })} />}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Wholesale;
