import React, { Component } from 'react';

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";
import Breadcrumb from "./../../header/Breadcrumb";
import ModalWindow from "./../../modal/ModalWindow";

class Retail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            read: false,
            show: false,
            message: "",
            redirect: true,
            modalIsOpen: false,
            data: {
                tel: "",
                email: "",
                confirm: "",
                fullName: "",
                password: "",
                newsletter: false,
                requisites: [{ company: '', bin: '', address: '' }]
            }
        };

        this.handleSend = this.handleSend.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }
    
    handleCheckClick() {
        this.setState((prevState) => ({ read: !prevState.read }));
    }

    checkData() {
        let result = true; 

        const {data} = this.state;

        if (data.tel == "") {
            this.setState({ show: true, message: "Заполните номер телефона", redirect: false });

            result = false;
        }

        if (data.fullName == "") {
            this.setState({ show: true, message: "Заполните Ф.И.О.", redirect: false });

            result = false;
        }

        if (data.password == "") {
            this.setState({ show: true, message: "Заполните пароль", redirect: false });

            result = false;
        }

        if (data.confirm == "") {
            this.setState({ show: true, message: "Повторите пароль", redirect: false });

            result = false;
        }

        if (data.password != data.confirm) {
            this.setState({ show: true, message: "Введеные пароли не совпадают", redirect: false });

            result = false;
        }

        return result;
    }

    handleSend = async (event) => {
        event.preventDefault();

        if (this.checkData()) {
            try {
                const response = await fetch('/api/user/retail/create', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.state.data)
                });

                const result = await response.json();

                this.setState({ message: result.message, show: true, redirect: result.value });

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    };

    render() {

        const { data, show, message, redirect } = this.state;

        return (
            <div className="page-content container" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>

                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Регистрация (Розничный клиент)"],
                ])} />

                <div className="row">
                    <div className="col-sm-12">
                        <h1>Регистрация</h1>
                        <p>Если Вы уже зарегистрированы, перейдите на страницу <a href="/login">авторизации</a>.</p>
                        <form onSubmit={this.handleSend}>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <div className='row'>
                                    <div className="col-12">
                                        <label className="label" for="input-firstname"><span style={{ color: "red" }}>*</span>&nbsp;<b>Ф. И. О.</b></label>
                                        <input type="text" value={ data.fullName } placeholder="Ф. И. О." className="form-control" onChange={(e) => {this.setState(prevState => ({data: {...prevState.data, fullName: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <br/>
                                <div className='row'>
                                    <div className="col-6">
                                        <label className="label" for="input-email"><b>email</b></label>
                                        <CheckEmail currentEmail = { data.email } className="form-control" onChange={(e) => {this.setState(prevState => ({data: {...prevState.data, email: e.target.value}}))}}/>                                    
                                    </div>
                                    <div className="col-6">
                                        <label className="label" for="input-telephone"><span style={{ color: "red" }}>*</span>&nbsp;<b>Телефон</b></label>
                                        <Telphone tel={ data.tel } onChange={(e) => {this.setState(prevState => ({data: {...prevState.data, tel: e.target.value}}))}}/>
                                    </div>
                                </div>
                            </fieldset>
                            <br/>
                            <fieldset>
                                <legend>Ваш пароль</legend>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Пароль</b></label>
                                        <input type="password" value={data.password} placeholder="Пароль" className="form-control" onChange={(e) => { this.setState(prevState => ({ data: { ...prevState.data, password: e.target.value } })) }} />
                                    </div>
                                    <div className="col-6">
                                        <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Подтверждение пароля</b></label>
                                        <input type="password" value={data.confirm} placeholder="Подтверждение пароля" className="form-control" onChange={(e) => { this.setState(prevState => ({ data: { ...prevState.data, confirm: e.target.value } })) }} />
                                    </div>
                                </div>
                            </fieldset>
                            <br/>
                            { data.email != "" &&
                                <fieldset>
                                    <legend>Рассылка новостей</legend>
                                    <div className="form-group">
                                        <label className="col-sm-12 control-label">Я хочу получать рассылку об акциях и спецпредложениях</label>
                                        <div className="col-sm-10">
                                            <label className="radio-inline">
                                                <input type="radio" checked={this.state.data.newsletter === true} onChange={(e) => {this.setState(prevState => ({data: {...prevState.data, newsletter: true}}))}}/>
                                                &nbsp;Да
                                            </label>
                                            &nbsp;
                                            &nbsp;
                                            <label className="radio-inline">
                                                <input type="radio" checked={this.state.data.newsletter === false} onChange={(e) => {this.setState(prevState => ({data: {...prevState.data, newsletter: false}}))}}/>
                                                &nbsp;Нет
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            }

                            <div className="buttons">
                                <div className="pull-right">
                                    <input type="checkbox" onClick={this.handleCheckClick} />
                                    &nbsp;Я&nbsp;прочитал&nbsp;
                                    <a href="/information" className="agree">
                                        <b>
                                            политику&nbsp;безопасности
                                        </b>
                                    </a>&nbsp;и&nbsp;согласен&nbsp;с&nbsp;условиями&nbsp;безопасности&nbsp;и&nbsp;обработки&nbsp;персональных&nbsp;данных&nbsp;<br /><br />
                                </div>
                                <button type="submit" style={{ display: "block!important" }} disabled={!this.state.read} className="btn btn-primary">Продолжить</button>
                                {show && <ModalWindow value={message} redirect={redirect} redirectUrl={"/"} onClose={() => this.setState({ show: false })} />}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Retail;