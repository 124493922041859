import React from 'react'

import Carousel from "./../../utils/Carousel"
import Breadcrumb from "./../../header/Breadcrumb"

export default function Garantiya() {

    const values = [
        {
            "id": 1,
            "img": "./../images/certificates/1.jpg",
        },
        {
            "id": 2,
            "img": "./../images/certificates/2.jpg",
        },
        {
            "id": 3,
            "img": "./../images/certificates/3.jpg",
        },
        {
            "id": 4,
            "img": "./../images/certificates/4.jpg",
        },
        {
            "id": 5,
            "img": "./../images/certificates/5.jpg",
        },
        {
            "id": 6,
            "img": "./../images/certificates/6.jpg",
        },
    ];

    return (
        <>
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Гарантия"],
                    ])} />

                    <h1 className="page-content__title section-title">
                        Гарантия
                    </h1>

                    <div className="page-content__description">

                        <p>Если на товар не установлен гарантийный срок или срок годности, требования, связанные с недостатками товара, могут быть предъявлены потребителем при условии, что недостатки в проданном товаре были обнаружены в пределах двух лет со дня передачи товара потребителю, если более длительные сроки не установлены законодательством РК или договором.</p>
                        <p>Гарантийные обязательства на товар распространяются при условии, если ТОО «Пятый Элемент SP» не докажет, что недостатки в товаре возникли после их передачи потребителю вследствие нарушения потребителем правил использования товара или его хранения либо действий третьих лиц или непреодолимой силы и соблюдения потребителем /покупателем/ следующих условий:</p>

                        <ul>
                            <li>установки товара квалифицированным специалистом на сертифицированной станции технического обслуживания (СТО);</li>
                            <li>эксплуатации и/или хранения товара в соответствии с нормативными документами, установленными техническими характеристиками, рекомендациями завода-изготовителя;</li>
                            <li>наличия заказ-наряда на установку товара на автомобиль сертифицированной СТО;</li>
                            <li>наличия в сертификате СТО разрешения на проведение данного вида работ для данного модельного ряда автомобиля;</li>
                            <li>наличия заключения о неработоспособности детали, выданного СТО;</li>
                            <li>соответствия установленного товара спецификации автомобиля.</li>
                        </ul>

                        <div className="guarantee">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="guarantee__block">

                                        <div className="guarantee__title">Гарантийные обязательства не распространяются:</div>
                                        <div className="guarantee__text">
                                            <ul>
                                                <li>на износ и естественное изменение состояния запасных частей при их эксплуатации на автомобиле в соответствии с требованиями производителя;</li>
                                                <li>на расходные материалы, технические жидкости и детали, замена которых предусмотрена при регулярном техническом обслуживании (масло, фильтры, автокосметика, автохимия, предохранители, тормозные колодки с претензией на скрип, писк и др.) после установки на автомобиль;</li>
                                                <li>на детали, со следами механических повреждений, полученных в результате неправильного хранения, неправильной установки либо эксплуатации с нарушением требований производителя.</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="guarantee__block">

                                        <div className="guarantee__title">Гарантийный случай не наступает и ТОО «Пятый Элемент SP» не несет ответственности за качество товара:</div>
                                        <div className="guarantee__text">

                                            <ul>
                                                <li>если дефекты товара явились следствием его неправильной эксплуатации, воздействия атмосферных явлений или иных внешних воздействий, нарушения правил установки и/или хранения;</li>
                                                <li>если имеет место нормальный для определённого производителем срока эксплуатации износ детали;</li>
                                                <li>если имеет место повреждение детали в результате ДТП, неправильной её установки или небрежной эксплуатации;</li>
                                                <li>если неисправность запасных частей топливной системы или системы выпуска произошла вследствие использования не предусмотрено автомобиля класса топлива;</li>
                                                <li>если повреждения (в особенности деталей подвески и рулевого управления) произошли из-за неаккуратного вождения на неровностях (экстремальная нагрузка на автомобиль);</li>
                                                <li>если имеются механические повреждения детали;</li>
                                                <li>если причиной выхода из строя товара явилось неисправность другой детали либо агрегата.</li>
                                            </ul>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>

                <div className="section section-certificates">

                    <div className="section-line certificates-line-top" >
                        <svg width="1970" height="95" viewBox="0 0 1970 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="1970" height="95">
                                <rect width="1970" height="95" fill="#D9D9D9"></rect>
                            </mask>
                            <g mask="url(#mask0_172_782)">
                                <path d="M-0.25 69H289.75L418.75 -0.5H1117.25L1264.25 88.5H1606.75L1679.25 45H1969.75V405L1701.25 406L1625.75 362H1288.25L1137.75 451H439.75L311.25 381.5H-0.25V69Z" fill="#0575C0"></path>
                            </g>
                        </svg>
                    </div>

                    <div className="container">
                    <div className="certificates-slider__wrapper" style={{ background: "#0575C0" }}>
                            <Carousel values={values} index={0}/>
                            <Carousel values={values} index={1}/>
                            <Carousel values={values} index={2}/>
                            <Carousel values={values} index={3}/>
                    </div>
                    </div>

                    <div className="section-line certificates-line-bottom">
                        <svg width="2000" height="86" viewBox="0 0 2000 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2000 45.0187L1696.96 45.0186L1622.29 2.50006L1280.96 2.5L1142.29 85.463L438.16 85.4628L309.28 15.0728H0V0H2000V45.0187Z" fill="#0575C0"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}
