import React, { Component } from 'react';

import Breadcrumb from '../../header/Breadcrumb';
import ModalThanks from '../../modal/ModalThanks';

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            question: "",
            showThankYou: false,
        };

        this.handleSendClick = this.handleSendClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    async handleSendClick() {
        try {
            const response = await fetch('/api/message', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: this.state.name, email: this.state.email, question: this.state.question })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            this.setState({showThankYou: true});

        } catch (error) {
            console.error('Failed to fetch cart:', error);
        }
    }

    handleCloseModal() {
        this.setState({ showThankYou: false }, () => {
            window.location.href = '/';
        });
    }
    
    render() {
        const { name, email, question, showThankYou } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Контакты"],
                    ])} />

                    <div className="row">
                        <div className="col-sm-12">
                            <h1>Контакты</h1>
                            <h3>Наше местонахождение</h3>
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <strong>Пятый элемент: интернет-магазин автозапчастей в Казахстане, Алматы</strong>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ul style={{ paddingLeft: 30 }}>
                                                <li>ул. Жамбыла 204, Алматы, Казахстан</li>
                                                <li>ул. Емцова 26а, Алматы, Казахстан</li>
                                                <li>ул. Райымбека 257а, Алматы, Казахстан</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <strong>Телефон:&nbsp;</strong>
                                            +7&nbsp;(707)&nbsp;763&nbsp;35&nbsp;61<br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <legend>Форма обратной связи</legend>
                                <div className="form-group required">
                                    <label className="col-sm-2 control-label" for="input-name">Ваше имя:</label>
                                    <div className="col-sm-10">
                                        <input type="text" name="name" value={name} className="form-control" onChange={(e) => {this.setState(prevState => ({...prevState, name: e.target.value}))}} />
                                    </div>
                                </div>
                                <br />
                                <div className="form-group required">
                                    <label className="col-sm-2 control-label" for="input-email">Ваш email:</label>
                                    <div className="col-sm-10">
                                        <input type="text" name="email" value={email} className="form-control" onChange={(e) => {this.setState(prevState => ({...prevState, email: e.target.value}))}} />
                                    </div>
                                </div>
                                <br />
                                <div className="form-group required">
                                    <label className="col-sm-6 control-label" for="input-enquiry">Ваш вопрос или сообщение:</label>
                                    <div className="col-sm-10">
                                        <textarea name="enquiry" rows="10" className="form-control" value={question} onChange={(e) => {this.setState(prevState => ({...prevState, question: e.target.value}))}} ></textarea>
                                    </div>
                                </div>
                            <div className="buttons">
                                <div className="pull-right">
                                    <input className="btn btn-primary" type="submit" value="Отправить сообщение" onClick={this.handleSendClick}/>
                                    {showThankYou && <ModalThanks onClose={this.handleCloseModal} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;