import React, { Component } from 'react';

import Breadcrumb from '../../header/Breadcrumb'

class HowToBuy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: window.location.hostname
        };
    }

    render() {
        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Как купить в интернет магазине " + this.state.value], 
                    ])} />

                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="page-content__title section-title">Как купить в интернет магазине {this.state.value}</h1>
                            <div className="page-content__description">
                                <br />
                                <p>Данная инструкция описывает этапы совершения покупки в интернет-магазине {this.state.value}</p>
                                <br />
                                <br />
                                <h3>
                                    <b>1. Откройте сайт {this.state.value}</b>
                                </h3>
                                <p>Перед вами главная страница. Здесь вы можете ознакомиться как с акционными и скидочными предложениями, так и ввести в поиск номер интересующей вас запчасти.</p>
                                <p>
                                    <img src="./images/buy/1.png" style={{ width: 885 }} alt="" />
                                    <br />
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>2. Поиск по каталогу</b>
                                </h3>
                                <p>
                                    <span>В разделе каталог вы можете выбрать интересующую вас категорию или вбить в поиск запрос по номеру или наименованию запчасти. Вы можете как сразу нажать на кнопку “Добавить в корзину”, так и перейти на страницу с описанием товара. </span>
                                </p>
                                <p>
                                    <span>
                                        <br />
                                    </span>
                                    <img src="./images/buy/2.png" alt="" />
                                    <br />
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>3. Выбор товара</b>
                                </h3>
                                <p>
                                    <span>Откройте интересующий вас товар. На странице вы также можете увидеть количество товара в наличие и кросс-номера</span>
                                </p>
                                <p>
                                    <img src="./images/buy/3.png" style={{ width: 878 }} alt="" />
                                    <span>
                                        <br />
                                    </span>
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>4. Добавление товара в корзину</b>
                                </h3>
                                <p>
                                    <span>При добавлении товара в корзину появится всплывающее окно. В нем вы можете выбрать кнопку “Продолжить покупки” или “Перейти в корзину” для оформления покупки.</span>
                                </p>
                                <p>
                                    <img src="./images/buy/4.png" style={{ width: 909 }} alt="" />
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>5.Оформление заказа</b>
                                </h3>
                                <p>
                                    <span>На странице вы увидите все товары, добавленные вами в корзину. <br />
                                        <br />Для успешного оформления заказа совершите следующие шаги: <br />
                                        <br />1. Скорректируйте количество товаров (в случае отсутствия товара в указанном вами количестве вы увидите уведомление). <br />2. Введите Имя, Фамилию, Контактный телефон и Email. <br />3. Выберите подходящий вам способ доставки. <br />4. Укажите предпочтительный способ оплаты. </span>
                                </p>
                                <p>
                                    <img src="./images/buy/5.png" alt="" />
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>6.Способы оплаты</b>
                                </h3>
                                <p>
                                    <span>Оплата кредитной картой онлайн на сайте <br />В случае выбор способа оплаты “Кредитной карты” вам откроется окно для оплаты. Заполните данные своей карты и оплатите покупку. </span>
                                    <br />
                                </p>
                                <br />
                                <br />
                                <p>
                                    <img src="./images/buy/6.jpg" alt="" />
                                    <br />
                                </p>
                                <br />
                                <br />
                                <p>
                                    <span>Оплата с помощью Kaspi QR <br />В случае выбор способа оплаты “Kaspi”: <br />С мобильных устройств: вас перенесет в приложение Kaspi на вашем устройстве <br />С ноутбука: вам откроется всплывающее окно с qr кодом. Отсканируйте qr в приложении Kaspi на вашем мобильном устройстве </span>
                                </p>
                                <br />
                                <br />
                                <p>
                                    <img src="./images/buy/7.png" alt="" />
                                    <span>
                                        <br />
                                    </span>
                                </p>
                                <br />
                                <br />
                                <h3>
                                    <b>7.Подтверждение заказа</b>
                                </h3>
                                <p>
                                    <span>Финальным шагом вам откроется окно с подтверждением заказа.</span>
                                </p>
                                <p>
                                    <img src="./images/buy/8.png" alt="" />
                                    <br />
                                </p>
                                <br />
                                <br />
                                <br />
                                <br />
                                <h2>Приятных вам покупок!</h2>
                                <br />
                                <br />
                                <a href="/catalog" className="btn btn-primary" style={{ marginBottom: 30 }}>Перейти в каталог</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HowToBuy;