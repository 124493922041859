const ModeService = async () => {
  try {
    const response = await fetch('/api/service', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result.value; 
  } catch (error) {
    console.error('Error fetching service mode:', error);
    return false;
  }
};

export default ModeService;
