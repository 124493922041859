import React from 'react'

export default function SelectionTop() {
    return (
        <div className="section section-top" style={{marginTop: -50, boxSizing: "border-box" }}>
            <div className="container">

                <div className="section-top__swiper swiper-container-initialized swiper-container-horizontal">
                    <div className="row swiper-wrapper" style={{ transform: "translate3d(0px, 0px, 0px)" }}>

                        <div className="swiper-slide col-lg-6 action-item__main swiper-slide-active" style={{ width: 429 }}>
                            <div className="action-item">
                                <div className="action-item__img">
                                    <img src="./images/banner/banner-1-1.jpg" data-src="./images/banner/banner-1-1.jpg" alt="Shin HWA" />
                                </div>
                                <div className="action-item__content">
                                    <div className="action-item__title">
                                        ТОП продаж
                                    </div>
                                    <div className="action-item__desc" style={{ display: "none" }}>
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5.86534V7.54119H16.4606L12.9785 12.1498L13.8775 13.3396L18.8917 6.70327L13.8775 0.0668945L12.9785 1.25675L16.4606 5.86534H0Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/**
                                    <a href="/manufacture/shinhwa" className="action-item__link">Подробнее</a>
                                */}
                            </div>
                        </div>

                        <div className="swiper-slide col-lg-6 action-item__main swiper-slide-next" style={{ width: 429 }}>
                            <div className="action-item">
                                <div className="action-item__img">
                                    <img src="./images/banner/banner-1-2.jpg" data-src="./images/banner/banner-1-2.jpg" alt="CTR" />
                                </div>
                                <div className="action-item__content" style={{ display: "none" }}>
                                    <div className="action-item__title">
                                        CTR
                                    </div>
                                    <div className="action-item__desc">
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5.86534V7.54119H16.4606L12.9785 12.1498L13.8775 13.3396L18.8917 6.70327L13.8775 0.0668945L12.9785 1.25675L16.4606 5.86534H0Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/**
                                    <a href="/manufacture/ctr" className="action-item__link">Подробнее</a>
                                */}
                            </div>
                        </div>

                        <div className="swiper-slide col-lg-4" style={{ width: 429 }}>
                            <div className="action-item">
                                <div className="action-item__img">
                                    <img src="./images/banner/banner-1-3.jpg" data-src="./images/banner/banner-1-3.jpg" alt="NAP" />
                                </div>
                                <div className="action-item__content" style={{ display: "none" }}>
                                    <div className="action-item__title">
                                        NAP
                                    </div>
                                    <div className="action-item__desc">
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5.86534V7.54119H16.4606L12.9785 12.1498L13.8775 13.3396L18.8917 6.70327L13.8775 0.0668945L12.9785 1.25675L16.4606 5.86534H0Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/**
                                    <a href="/manufacture/nap" className="action-item__link">Подробнее</a>
                                */}
                            </div>
                        </div>

                        <div className="swiper-slide col-lg-4" style={{ width: 429 }}>
                            <div className="action-item">
                                <div className="action-item__img">
                                    <img src="./images/banner/banner-1-4.jpg" data-src="./images/banner/banner-1-4.jpg" alt="YUIL FILTER, Корея" />
                                </div>
                                <div className="action-item__content" style={{ display: "none" }}>
                                    <div className="action-item__title">
                                        YUIL FILTER, Корея
                                    </div>
                                    <div className="action-item__desc">
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5.86534V7.54119H16.4606L12.9785 12.1498L13.8775 13.3396L18.8917 6.70327L13.8775 0.0668945L12.9785 1.25675L16.4606 5.86534H0Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/** 
                                    <a href="/manufacture/yuilfilter" className="action-item__link">Подробнее</a>
                                */}
                            </div>
                        </div>

                        <div className="swiper-slide col-lg-4" style={{ width: 429 }}>
                            <div className="action-item">
                                <div className="action-item__img">
                                    <img src="./images/banner/banner-1-5.jpg" data-src="./images/banner/banner-1-5.jpg" alt="Carstation" />
                                </div>
                                <div className="action-item__content" style={{ display: "none" }}>
                                    <div className="action-item__title">
                                        Carstation
                                    </div>
                                    <div className="action-item__desc">
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5.86534V7.54119H16.4606L12.9785 12.1498L13.8775 13.3396L18.8917 6.70327L13.8775 0.0668945L12.9785 1.25675L16.4606 5.86534H0Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/** 
                                    <a href="/manufacture/carstation" className="action-item__link">Подробнее</a>
                                */}
                            </div>
                        </div>

                    </div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>

                <div className="news__footer">
                    {/*
                        <a href="/manufacture" className="news__all" style={{ background: "#333", color: "#fff", border: 0 }}>Все производители</a>
                    */}
                </div>

            </div>

            <div className="section-line-bg">
                <svg width="2000" height="93" viewBox="0 0 2000 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M301.199 15L-2.62267e-06 14.9999L1.37405e-05 92.9995L2000 93L2000 36.0001L1685.25 36.0001L1619.59 0.000283179L1292.34 0.00022596L1146.43 81.9997L422.095 81.9996L301.199 15Z" fill="#DFDFDF"></path>
                </svg>
            </div>

            <div className="section-line">
                <svg width="2000" height="87" viewBox="0 0 2000 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2000 44.5189L1696.96 44.5188L1622.29 2.00028L1280.96 2.00022L1142.29 84.9632L438.16 84.963L309.28 14.573L-1.93027e-06 14.573" stroke="#6D6E71" strokeWidth="3" strokeMiterlimit="10"></path>
                </svg>
            </div>
            
        </div>
    )
}
