import React, { Component } from 'react';

import ProductDiscounted from '../catalog/ProductDiscountedMain';
import Token from "./../../utils/Token";
import Discount from "./../../utils/Discount";

class SectionDiscounted extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
        };
    }

    async getProducts() {
        const response = await fetch("/api/productListDiscount/0/12?token=" + (new Token()).get(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            return [];
        }

        return await response.json();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.products !== this.props.products) {
            this.setState({
                products: this.props.products,
            });
        }
    }

    async componentDidMount() {
        const products = await this.getProducts();

        this.setState({
            products,
        })
    }

    showAll() {
        (new Discount().update(true));

        window.location.href = `./catalog`;
    }

    renderProductItems(products) {
        if (products.length > 0) {
            return products.slice(0, 13).map((product) => (
                <ProductDiscounted key={product.uuid} product={product} />
            ));
        }
    }

    render() {
        const { products } = this.state;

        if (products.length > 0) {
            return (
                <div className="section section-discounted">
                    <div className="container">

                        <div className="section-title">
                            <center>Товары со скидкой</center>
                        </div>

                        <div className="discounted-catalog">
                            <div className="row">
                                {this.renderProductItems(products)}
                            </div>
                        </div>

                        <div className="news__footer">
                            <button className="news__all" style={{cursor: "pointer", background: "#333", color: "#fff", border: 0 }} onClick={() => this.showAll()}>Показать все</button>
                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div style={{minHeight: 20, maxHeight: 10}}></div>
            )
        }
    }
}

export default SectionDiscounted;