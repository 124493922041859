import UserType from './../enum/UserType';

class User {
    constructor() {
        this.name = "";
        this.type = UserType.UNDEFINED;
    }

    get() {
        return localStorage.getItem('name');
    } 
    
    update(name) {
        localStorage.setItem('name', name);
    }

    getType() {
        return localStorage.getItem('type');
    } 

    updateType(type) {
        localStorage.setItem('type', type);
    }

    delete() {
        localStorage.removeItem('type');
        localStorage.removeItem('name');
    }
}

export default User;
