import React, { Component } from 'react';

import HeaderLogo from './top/HeaderLogo'
import HeaderCart from './top/HeaderCart'
import HeaderRing from './top/HeaderRing'
import HeaderLogin from './top/HeaderLogin'
import HeaderContacts from './top/HeaderContacts'

import HeaderMenu from './bottom/HeaderMenu'
import HeaderCatalog from './bottom/HeaderCatalog'

import Meta from "./../utils/Meta"

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                
                <Meta location={window.location} />

                <div className="header-top">
                    <div className="container">
                        <div className="header-row">

                            <HeaderLogo />

                            <div className="header-right">

                                <HeaderContacts />
                                <HeaderLogin />
                                <HeaderCart/>
                                <HeaderRing />

                            </div>

                        </div>
                    </div>
                </div>

                <div className="header-bottom">
                    <div className="container">

                        <div className="header-bottom-row">

                            <HeaderCatalog />
                            <HeaderMenu />

                        </div>

                    </div>
                </div>

            </header>
        )
    }
}

export default Header;