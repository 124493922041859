import React from 'react'

export default function SectionNews() {
    return (
        <div className="section section-news">

            <div className="section-news-bg">
                <img src="./images/news-bg-title.png" alt=""/>
            </div>
            <div className="container">

                <div className="section-title">
                    Новости
                </div>

                <div className="news-container">

                    <div className="row">

                        <div className="col-lg-12 col-md-6 col-sm-6 news-item__col news-item__main">

                            <div className="news-item">
                                <div className="news-item__img">
                                    <a href="/blog?id=1">
                                        <img src="./images/news/image_41.png" data-src="./images/news/image_41.png" alt="" title="" />
                                    </a>
                                </div>
                                <div className="news-item__content">
                                    <div className="news-item__date">
                                        27.12.2023
                                    </div>
                                    <div className="news-item__title">
                                        Участие "Пятого элемента" в выставке MIMS AUTOMOBILITY MOSCOW 2023
                                    </div>
                                    <div className="news-item__desc">
                                        Продолжая традицию успешного участия в ключевых отраслевых событиях, компания "Пятый элемент" вновь заявляет о своем участии в выставке MIMS Automechanika Moscow 2023. Это событие, являющееся одним из самых значимых в автомобильной индустрии, предоставит компании платформу для демонстрации своих пос
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 news-item__col">

                            <div className="news-item">
                                <div className="news-item__img">
                                    <a href="/blog?id=2">
                                        <img src="./images/news/carMechanic.jpg" data-src="./images/news/carMechanic.jpg" alt="" title="" />
                                    </a>
                                </div>
                                <div className="news-item__content">
                                    <div className="news-item__date">
                                        05.11.2023
                                    </div>
                                    <div className="news-item__title">
                                        Советы по обслуживанию для увеличения срока службы автозапчастей
                                    </div>
                                    <div className="news-item__desc">
                                        Чтобы максимально продлить срок службы автозапчастей и сохранить ваш автомобиль в отличном рабочем состоянии, важно следовать нескольким основным принципам ухода и обслуживания. Вот более подробное руководство по каждому из этих аспектов:
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 news-item__col">

                            <div className="news-item">
                                <div className="news-item__img">
                                    <a href="/blog?id=3">
                                        <img src="./images/news/2023.jpg" data-src="./images/news/2023.jpg" alt="" title="" />
                                    </a>
                                </div>
                                <div className="news-item__content">
                                    <div className="news-item__date">
                                        04.11.2023
                                    </div>
                                    <div className="news-item__title">
                                        Компания "Пятый элемент" - гордый титульный спонсор дрифт команды BurnoutZ
                                    </div>
                                    <div className="news-item__desc">
                                        Компания "Пятый элемент" удостаивается статуса титульного спонсора команды BurnoutZ, воплощая страсть к автоспорту и поддержку дрифт культуры в Казахстане. С гордостью поддерживая талант Михаила Пака, Чемпиона по дрифту 2023, компания занимает лидирующие позиции не только на рынке автозапчастей, но
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 news-item__col">

                            <div className="news-item">
                                <div className="news-item__img">
                                    <a href="/blog?id=4">
                                        <img src="./images/news/image_39.png" data-src="./images/news/image_39.png" alt="" title="" />
                                    </a>
                                </div>
                                <div className="news-item__content">
                                    <div className="news-item__date">
                                        15.11.2022
                                    </div>
                                    <div className="news-item__title">
                                        Участие "Пятого элемента" в выставке MIMS AUTOMOBILITY MOSCOW 2022
                                    </div>
                                    <div className="news-item__desc">
                                        Компания "Пятый элемент", лидер на рынке автозапчастей в Казахстане, гордо анонсирует свое участие в престижной международной выставке MIMS Automechanika Moscow 2022. Это событие стало платформой для демонстрации последних достижений компании в области автокомпонентов и укрепления деловых связей на
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="news__footer">
                        {/*
                        <a href="/news" className="news__all">Показать все</a>
                        */}
                    </div>

                </div>

            </div>
        </div>
    )
}
