import React, { Component } from 'react';

import Breadcrumb from "./../../header/Breadcrumb";

import Download from "./../../utils/Download";

import { formatDate } from "./../../utils/FormattedDate";
import { formatAmount } from "./../../utils/Format";

class Document extends Component {

    constructor(props) {
        super(props);

        this.state = {
            document: {
                date: this.props.date,
                number: this.props.number,
                amount: this.props.amount,
                comment: this.props.comment,
                table: [],
            },
            download: {},
        };
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        try {
            const response = await fetch(`/api/document/${id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const document = await response.json();

            this.setState({ document, download: this.createDownloadData(document) });

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.document !== this.props.document) {
            this.setState({
                document: this.props.document,
                download: this.createDownloadData(this.props.document)
            });
        }
    }

    createDownloadData(document) {
        const downloadData = {
            date: document.date,
            number: document.number,
            amount: document.amount,
            comment: document.comment,
            table: document.table.map(item => ({
                name: item.name,
                count: item.count,
                price: item.price,
                original: item.original,
                amount: item.amount,
            })),
        };
        return downloadData;
    }

    render() {
        const { document, download } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, minHeight: 600, boxSizing: "border-box" }}>
                <div className="container">
                    <Breadcrumb values={
                        new Map([
                            ["/", "Главная"],
                            ["/account", "Личный кабинет"],
                            ["/#", "Заказ №" + document.number + " от " + formatDate(document.date)],
                        ])
                    } />

                    <div className="row">
                        <div className="col-12">

                            <form>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h1 className="page-content__title">
                                            {"Заказ №" + document.number + " от " + formatDate(document.date)}
                                        </h1>
                                    </div>
                                    <div>
                                        <Download data={download} />
                                    </div>
                                </div>
                                <br/>
                                <div className='d-flex justify-content-between'>
                                    <div></div>
                                    <div>
                                        <label htmlFor="amount">
                                            {document.status == "ASSEMBLY" && 
                                                "Статус: На сборке"
                                            }
                                            {document.status == "CANCELLED" && 
                                                "Статус: Отменен"
                                            }
                                            {document.status == "COMPLETED" && 
                                                "Статус: Завершен"
                                            }
                                        </label>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <td className="text-center">
                                                        <b>
                                                            Номер производителя
                                                        </b>
                                                    </td>
                                                    <td className="text-center">
                                                        <b>
                                                            Наименование
                                                        </b>
                                                    </td>
                                                    <td className="text-center">
                                                        <b>
                                                            Количество
                                                        </b>
                                                    </td>
                                                    <td className="text-center">
                                                        <b>
                                                            Цена
                                                        </b>
                                                    </td>
                                                    <td className="text-center">
                                                        <b>
                                                            Сумма
                                                        </b>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {document.table.map((value, index) => (
                                                    <tr key={index}>
                                                        <td className="text-left">
                                                            <a href={"/product/" + value.product}>
                                                                {value.original}
                                                            </a>
                                                        </td>
                                                        <td className="text-left">
                                                            <a href={"/product/" + value.product}>
                                                                {value.name}
                                                            </a>
                                                        </td>
                                                        <td className="text-right">
                                                            <a href={"/product/" + value.product}>
                                                                {formatAmount(value.count)}
                                                            </a>
                                                        </td>
                                                        <td className="text-right">
                                                            <a href={"/product/" + value.product}>
                                                                {formatAmount(value.price)}
                                                            </a>
                                                        </td>
                                                        <td className="text-right">
                                                            <a href={"/product/" + value.product}>
                                                                {formatAmount(value.amount)}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div></div>
                                    <div>
                                        <label htmlFor="amount">
                                            {"Сумма: " + formatAmount(document.amount)}
                                        </label>
                                    </div>
                                </div>
                                
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment">
                                                Комментарий:
                                            </label>
                                            <textarea readOnly name="comment" rows="2" className="form-control" value={document.comment}></textarea>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Document;
