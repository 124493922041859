import React, { Component } from 'react';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: this.props.count,
            current: this.props.current,
        };

        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
        this.set = this.set.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.count !== this.props.count) {
            this.setState({
                count: this.props.count,
            });
        }
    }

    up() {
        let current = this.state.current;
        if ((current + 1) >= (this.state.count )) {
            current = this.state.count;
        } else {
            current += 1;
        }
        this.setState({
            current: current
        });
        this.scrollToTop();
        this.props.onPageChange(current)
    }

    down() {
        let current = this.state.current;
        if ((current - 1) <= 0) {
            current = 0;
        } else {
            current -= 1;
        }
        this.setState({
            current: current
        });
        this.scrollToTop();
        this.props.onPageChange(current)
    }

    set(i) {
        this.setState({
            current: i
        });
        this.scrollToTop();
        this.props.onPageChange(i)
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    render() {
        const { count, current } = this.state;
        const pagesToShow = 10;
        const pageArray = [];

        if (count <= pagesToShow) {
            for (let i = 0; i < count; i++) {
                pageArray.push(i);
            }
        } else {
            pageArray.push(0);
            
            if (current > 3) {
                pageArray.push('...');
            }

            for (let i = Math.max(1, current - 1); i <= Math.min(count - 2, current + 1); i++) {
                pageArray.push(i);
            }
            
            if (current < count - 4) {
                 pageArray.push('...');
            }

            pageArray.push(count - 1); 
        }
        return (
            <div className="page-content__pagination">
                {count > 1 &&
                    <ul className="pagination">
                        <div style={{ display: current >= 1 ? 'block' : 'none' }}>
                            <li className="page-item page-item__auto">
                                <button className="page-link" onClick={this.down} style={{boxShadow: "none"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 4L8 12L16 20" stroke="#0575C0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    Предыдущая
                                </button>
                            </li>
                        </div>
                        {pageArray.map((page, i) =>
                            page === '...' ? (
                                <li className="page-item" key={i}>
                                    <span className="page-link">...</span>
                                </li>
                            ) : (
                                <li className={current === page ? 'page-item active' : 'page-item'} key={i}>
                                    <button className="page-link" onClick={() => this.set(i)} style={{boxShadow: "none"}}>
                                        <span className="page-link">{i + 1}</span>
                                    </button>
                                </li>
                            )
                        )}
                            <div style={{ display: current < (count - 1) ? 'block' : 'none' }}>
                                <li className="page-item page-item__auto">
                                    <button className="page-link" onClick={this.up} style={{boxShadow: "none"}}>
                                        Следующая
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 4L16 12L8 20" stroke="#0575C0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </button>
                                </li>
                            </div>
                    </ul>
                }
            </div>
        );
    }
}

export default Pagination;
