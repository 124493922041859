class Discount {
    constructor() {
        if (localStorage.getItem('discount') === null) {
            this.update(false);
        }
    }

    get() {
        return localStorage.getItem('discount') === 'true';
    }

    update(value) {
        localStorage.setItem('discount', value.toString());
    }

    delete() {
        localStorage.removeItem('discount');
    }
}

export default Discount;
