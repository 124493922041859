import React, { Component } from 'react';

import Format from "../../utils/Format";
import User from '../../utils/User';
import UserType from '../../enum/UserType';
import EventBus from './../../utils/EventBus';
import Token from './../../utils/Token';
import ToastNotification from '../../utils/ToastNotification';

class ProductDiscountedMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 1,
            countCart: 0,
            rating: this.props.product.rating,
            product: this.props.product,
            remainsMax: this.props.remainsMax,
            showToast: false,
        };

        this.edit = this.edit.bind(this);
        this.plus = this.plus.bind(this);
        this.minus = this.minus.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    componentDidMount() {
        this.getCountOnCart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.product !== this.props.product) {
            this.setState({
                product: this.props.product,
            }, () => {
                this.getCountOnCart();
            });
        }
    }

    async getCountOnCart() {
        const { product } = this.state;
        try {
            const response = await fetch(`/api/cart/product/count/${product.uuid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                }
            });

            if (response.ok) {
                const result = await response.json();

                this.setState({ countCart: result.count });
            }
        } catch (error) {
            this.setState({ countCart: 0 });
        }
    }

    renderStars() {
        const { rating } = this.state;
        let stars = [];

        for (let i = 0; i < rating; i++) {
            stars.push(<React.Fragment key={i}>{'\u00A0'}<img src={`./../images/rating/star_on.png`} alt="star" /></React.Fragment>);
        }

        if (rating < 5) {
            for (let i = 0; i < (5 - rating); i++) {
                stars.push(<React.Fragment key={i + rating}>{'\u00A0'}<img src={`./../images/rating/star_off.png`} alt="star" /></React.Fragment>);
            }
        }

        return stars;
    }

    plus() {
        const { count, product } = this.state;

        this.setState({
            count: Math.min(count + 1, product.count),
        });
    }

    minus() {
        const { count } = this.state;

        this.setState({
            count: Math.max(count - 1, 1),
        });
    }

    edit(e) {
        const value = parseInt(e.target.value, 10) || 1;
        const { product } = this.state;

        this.setState({
            count: Math.max(1, Math.min(value, product.count)),
        });
    }

    setHideToast() {
        this.setState({ showToast: false });
    }

    async handleAddToCart() {
        const { count, product } = this.state;

        try {
            const response = await fetch('/api/cart/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify({ uuid: product.uuid, count: count, amount: (count * product.price) })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.status == "OK") {
                this.setState({ showToast: true });
                EventBus.emit('cartUpdated', true);
                this.getCountOnCart();
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    render() {
        const { product, count, countCart, showToast } = this.state;

        let stylePrice = {};
        let styleHidden = { visibility: "hidden" };

        if (product.count === 0 || product.price == 0) {
            stylePrice = { position: "absolute", bottom: 0, paddingBottom: "15px", paddingRight: "15px" };
        }

        if (product.count > 0) {
            styleHidden = {};
        }

        return (
            <div key={product.uuid} className="col-lg-3 col-md-6 col-sm-6 product-col">

                <div className="product-item" style={{ minWidth: 250, maxWidth: 250, minHeight: 250 }}>

                    {countCart > 0 &&
                        <div className="product-item__content">
                            <div className="header-card">
                                <i className="header-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 6H17C17 3.2 14.8 1 12 1C9.2 1 7 3.2 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.7 3 15 4.3 15 6H9C9 4.3 10.3 3 12 3ZM19 20H5V8H19V20ZM12 12C10.3 12 9 10.7 9 9H7C7 11.8 9.2 14 12 14C14.8 14 17 11.8 17 9H15C15 10.7 13.7 12 12 12Z" fill="#000"></path>
                                    </svg>
                                    <span className="card-number">{countCart}</span>
                                </i>
                            </div>
                        </div>
                    }

                    <div className="product-item__image">
                        <a href={`product/${product.href}`}><img src={`/api/image/1/${product.uuid}`} alt={product.name} title={product.name} style={{ cursor: "pointer" }} /></a>
                    </div>
                    <div>
                        {product.price !== product.oldPrice && new User().getType() != UserType.WHOLESALE && (
                            <img className="sale-icon" src="./../images/sale.png" style={{ minWidth: 90, minHeight: 60 }} alt='sale' />
                        )}
                    </div>

                    <div className="product-item__content">
                        <div className="product-item__content--body">
                            <div className="product-item__article" style={{ fontSize: 11 }}>
                                <span style={{ minWidth: 100 }}>
                                    {product.original}
                                </span>
                                {product.count > 0 ? (
                                    <span>
                                        <span>Количество:</span>
                                        <span
                                            className="text_stock"
                                            style={{
                                                paddingLeft: 5,
                                                fontWeight: "bold",
                                                color: "green",
                                            }}
                                        >
                                            {product.count > 10 ? "10+" : product.count}
                                        </span>
                                    </span>
                                ) : (
                                    <span
                                        className="text_stock"
                                        style={{
                                            fontWeight: "bold",
                                            color: "red",
                                            right: 0,
                                        }}
                                    >
                                        Нет в наличии
                                    </span>
                                )}
                            </div>
                            <div className="product-item__article" style={{ position: "relative", margin: "auto", padding: "auto", top: 10 }}>
                                <span style={{ position: "relative", top: -3 }}>
                                    {this.renderStars()}
                                </span>
                            </div>
                            <div className="product-item__title">
                                <br />
                                <a href={`product/${product.href}`} style={{ fontSize: 14 }}>{product.name}</a>
                            </div>

                            {product.count > 0 && Number(product.price) !== Number(product.oldPrice) && (
                                <div className="product-item__prices" style={stylePrice}>
                                    <div className="product-item__new-price">
                                        <Format value={product.price} />&nbsp;₸
                                    </div>
                                    <div className="product-item__old-price">
                                        <Format value={product.oldPrice} />&nbsp;₸
                                    </div>
                                </div>
                            )}

                            {product.count > 0 && Number(product.price) === Number(product.oldPrice) && (
                                <div className="product-item__prices" style={stylePrice}>
                                    <div className="product-item__new-price">
                                        <Format value={product.price} />&nbsp;₸
                                    </div>
                                </div>
                            )}

                        </div>

                        <div style={styleHidden}>
                            <div className="product-item__input product-counter">

                                <button className="product-item__input-btn minus" type="button" onClick={this.minus}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.3337 17.3334H6.66699V14.6667H25.3337V17.3334Z" fill="#333333"></path>
                                    </svg>
                                </button>

                                <input className="product-item__input-control" type="text" value={count} onChange={this.edit} />

                                <button className="product-item__input-btn plus" type="button" onClick={this.plus}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.3337 17.3334H17.3337V25.3334H14.667V17.3334H6.66699V14.6667H14.667V6.66675H17.3337V14.6667H25.3337V17.3334Z" fill="#333333"></path>
                                    </svg>
                                </button>

                            </div>

                            <div className="product-item__content--footer goods__buy">

                                <div className="product-item__button">
                                    <button type="button" className="goods__buy-btn md-trigger" onClick={this.handleAddToCart}>Добавить в корзину</button>
                                </div>

                            </div>
                        </div>

                        {product.count <= 0 &&
                            <div className="product-item__content--footer goods__buy">
                                <div className="product-item__prices">
                                    <div className="product-item__new-price">
                                        <Format value={product.price} />&nbsp;₸
                                    </div>
                                    <div className="product-item__old-price">
                                        <Format value={product.oldPrice} />&nbsp;₸
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {showToast && <ToastNotification text={`Добавлено в корзину - ${product.name}, количество - ${count}`} onClose={() => this.setHideToast()} />}
            </div>
        )
    }
}

export default ProductDiscountedMain;