import React, { Component } from 'react';

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";
import Breadcrumb from "./../../header/Breadcrumb";
import ModalWindow from "./../../modal/ModalWindow";

class Forgotten extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tel: "",
            email: "",
            value: "",
            redirect: false,
            showModal: false,
            modalIsOpen: false,
        };

        this.handleForgottenClick = this.handleForgottenClick.bind(this);
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal = () => {
        if (this.subtitle) {
            this.subtitle.style.color = '#f00';
        }
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleForgottenClick = async (event) => {
        event.preventDefault();

        const { tel, email } = this.state;

        const pattern = /^[a-zA-Z0-9._%+-]{1,100}@[a-zA-Z0-9.-]{1,100}\.[a-zA-Z]{1,10}$/;
        if (tel === "" && (email === "" || !pattern.test(email))) {
            this.setState({ value: "Введите некорректный email или номер телефона", showModal: true, redirect: false });
        } else {
            try {
                const response = await fetch('/api/forgotten', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ tel: tel, email: email })
                });

                let value;
                if (!response.ok) {
                    value = `Ошибка: ${response.status}`;
                } else {
                    if (email !== "") {
                        value = "Новый пароль был выслан на ваш email.";
                    } 
                    if (tel !== "") {
                        value = "Новый пароль был выслан вам по смс.";
                    } 
                }

                this.setState({ value, showModal: true, redirect: response.ok });
            } catch (error) {
                console.error('Failed to fetch:', error);
                this.setState({ value: "Ошибка соединения", showModal: true, redirect: false });
            }
        }
    };

    render() {
        const { tel, email, value, redirect, showModal } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/account", " Личный Кабинет"],
                        ["/#", "Забыли пароль?"],
                    ])} />

                    <div className="row">
                        <div id="content" className="col-sm-12">
                            <h2>Забыли пароль?</h2>
                            <p>Введите email вашей учетной записи или телефон.</p>

                            <div className="form-group required">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <CheckEmail currentEmail = { email } className="form-control" onChange={(e) => {this.setState(prevState => ({email: e.target.value }))}}/>
                                    </div>

                                    <div className="col-sm-5">
                                        <Telphone tel={tel} onChange={(e) => this.setState({ tel: e.target.value })} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group required">
                                <div className="col-sm-10">
                                    <p>Нажмите кнопку "Получить", чтобы получить пароль по электронной почте или на телефон в виде смс.</p>
                                    <div className="buttons clearfix">
                                        <div className="pull-right">
                                            <input 
                                                type="button" 
                                                value="Получить" 
                                                className="btn btn-primary" 
                                                onClick={this.handleForgottenClick}
                                            />
                                            {showModal && <ModalWindow value={value} redirect={redirect} redirectUrl={"/"} onClose={() => this.setState({ showModal: false })} />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Forgotten;
