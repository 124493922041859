class Visit {
    get() {
        return localStorage.getItem('date');
    }

    set() {
        const currentDate = (new Date()).toISOString().split('T')[0];
        localStorage.setItem('date', currentDate);
    }

    async check() {
        const current = (new Date()).toISOString().split('T')[0];
        const storedDate = this.get();

        if (!storedDate) {
            this.set();

            try {
                const response = await fetch('/api/user/dateCount', { method: 'GET' });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Date count initialized:', data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        } else if (current !== storedDate) {
            this.set();

            try {
                const response = await fetch('/api/user/dateCount', { method: 'GET' });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Date count updated:', data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    }
}

export default Visit;
