const DeviceDetect = () => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const mobile = Boolean(
    /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Macintosh/i.test(userAgent) &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0)
  );
  return mobile;
};

export default DeviceDetect;
