import React, { Component } from 'react'

import Breadcrumb from '../../header/Breadcrumb'
import Questionnaire from './Questionnaire'

class Dealer extends Component {

    render() {

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Стать диллером"],
                    ])} />
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="page-content__title section-title">
                                Как стать дилером
                            </h1>

                            <div className="page-content__description">
                                <div className="page-content__title">
                                    Кто может стать дилером?
                                </div>
                                <br />
                                <ul className="pay_className--ul">
                                    <li className="list-item">
                                        <div className="pay_className--desc">
                                            Нашим дилером могут стать все, у кого есть опыт работы в области продажи автозапчастей.
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="page-content__description">
                                <div className="page-content__title">
                                    Преимущества работы с нами:
                                </div>
                                <br />
                                <ul className="pay_className--ul">
                                    <li className="list-item">
                                        <div className="pay_className--desc">
                                            Дилерские цены
                                        </div>
                                    </li>
                                    <li className="list-item">
                                        <div className="pay_className--desc">
                                            Бесплатная доставка между городами при заказе от 50 000 тг (доставка кузовных частей осуществляется за счет дилера)
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="page-content__description">
                                <div className="page-content__title">
                                    Условия для дилеров:
                                </div>
                                <br />
                                <ul className="pay_className--ul">
                                    <li className="list-item">
                                        <div className="pay_className--desc">
                                            Объем закупки 8 000 000 тг в квартал
                                        </div>
                                    </li>
                                    <li className="list-item">
                                        <div className="pay_className--desc">
                                            Срок действия дилерского соглашения 1 год
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-end mt-3">
                                        <Questionnaire title={"Заполнить анкету"} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Dealer;