import React, { Component } from 'react';

import HomeBanner from "./HomeBanner";
import SelectionTop from './SelectionTop';
import SectionDiscounted from './SectionDiscounted';
import SectionAdvantages from "./SectionAdvantages";
import SectionBrends from "./SectionBrends";
import SectionReviews from "./SectionReviews";
import SectionNews from "./SectionNews";
import SelectionCars from "./SelectionCars";
import SectionContacts from "./SectionContacts";

import User from './../../utils/User';
import UserType from './../../enum/UserType';

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isService: false
    };
  }

  async componentDidMount() {
    const { isService } = this.props; 
    
    this.setState({ isService: isService });
  }

  render() {
    const { isService } = this.state;

    if (isService) {
      return (<></>)
    }

    return (
      <>
        <HomeBanner />
        <SelectionCars />
        <SelectionTop />
        {new User().getType() != UserType.WHOLESALE && (
          <SectionDiscounted />
        )}
        <SectionAdvantages />
        <SectionBrends />
        <SectionReviews />
        <SectionNews />
        <SectionContacts />
      </>
    )
  }
}

export default Index;
