import React from 'react'

export default function HeaderLogo() {
  return (
    <div className="header-logo">
        <a href="/">
            <img src="./../images/logo-new.png" alt="КАЧЕСТВЕННЫЕ ЗАПЧАСТИ ДЛЯ ВАШЕГО АВТО" />
        </a>
    </div>
  )
}
