import React, { Component } from 'react';

import Modal from 'react-modal';

import User from './../utils/User';
import UserType from './../enum/UserType';

class ModalImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: 1,
            index: null,
            value: this.props.value,
            modalIsOpen: true,
        };

        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
    }

    componentDidMount() {
        let count = this.props.count

        if(count == 0) {
            count = 1
        }

        this.setState({ index: this.props.index, count: count });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index) {
            this.setState({ index: this.props.index });
        }
        if (prevProps.count !== this.props.count) {
            let count = this.props.count

            if(count == 0) {
                count = 1
            }
            
            this.setState({ count: count });
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    afterOpenModal() {
        if (this.subtitle) {
            this.subtitle.style.color = '#f00';
        }
    }

    next() {
        const { index, count } = this.state;

        if (index + 1 > count) {
            this.setState({ index: 1 });
        } else {
            this.setState({ index: index + 1 });
        }
    }

    prev() {
        const { index, count } = this.state;

        if (index - 1 <= 0) {
            this.setState({ index: count });
        } else {
            this.setState({ index: index - 1 });
        }
    }

    render() {
        const { index, value, modalIsOpen } = this.state;

        return (
            <>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={this.customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="md-content">
                        <div className="row">
                            <div className="col-1 d-flex align-items-center justify-content-center">
                                <button style={{ border: 0, margin: 0, background: "#FFF" }} onClick={() => this.prev()}>
                                    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22L2 12L12 2" stroke="#0575C0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>

                            <div className="col-10 center-block">
                                <center>
                                    {!value.noimage ? (
                                        <img src={"./../images/product/" + value.uuid + "/" + index + ".png"} title={value.name} alt={value.name} style={{ width: "90%", height: "90%" }} />
                                    ) : (
                                        <img src={"./../images/nophoto.png"} title={value.name} alt={value.name} style={{ width: "90%", height: "90%" }} />
                                    )}
                                </center>
                            </div>

                            <div className="col-1 d-flex align-items-center justify-content-center">
                                <button style={{ border: 0, margin: 0, background: "#FFF" }} onClick={() => this.next()}>
                                    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 2L12 12L2 22" stroke="#0575C0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </div>
                </Modal >
            </>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '100%',
            maxWidth: 800,
            transform: 'translate(-50%, -50%)',
        },
    };
}

export default ModalImage;
