import React, { Component } from 'react';

import EventBus from './../../utils/EventBus';

import Breadcrumb from "./../../header/Breadcrumb"
import Token from "./../../utils/Token";

class Checkout extends Component {

    constructor(props) {
        super(props);
    }
    
    async componentDidMount() {
        /*
        const { cart } = this.props.location.state;

        if (cart) {
            await fetch('/api/cart/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify(cart)
            });
        }
        */

        EventBus.emit('cartUpdated', true);
    }
    
    render() {
        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>

                <div className="container" style={{minHeight: 450}}>

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Заказ принят"], 
                    ])} />

                    <div className="page-content__desc">
                        <p>
                            Ваш заказ принят!
                        </p>
                        <p>
                            Если у Вас возникли вопросы, пожалуйста<a href="/contact">&nbsp;свяжитесь с нами</a>.
                        </p>
                        <p>
                            Спасибо за покупки в нашем интернет-магазине!
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="buttons">
                                <div className="pull-right">
                                    <a href="/" className="btn btn-primary">
                                        Продолжить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Checkout;