import React, { Component } from 'react';

import Breadcrumb from "./../../header/Breadcrumb";
import ModalWindow from "./../../modal/ModalWindow";

import User from "./../../utils/User";
import Token from "./../../utils/Token";

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";

import CryptoJS from 'crypto-js';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tel: "",
            show: false,
            email: "",
            type: "",
            qrShow: false,
            message: "",
            password: "",
            redirect: false,
            modalIsOpen: false,
            activeTab: 1,
        };
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal = () => {
        if (this.subtitle) {
            this.subtitle.style.color = '#f00';
        }
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleLogin = async (event) => {
        event.preventDefault();

        const { email, tel, password, activeTab } = this.state;

        if (activeTab == 1) {
            const pattern = /^[a-zA-Z0-9._%+-]{1,100}@[a-zA-Z0-9.-]{1,100}\.[a-zA-Z]{1,10}$/;
            if (!pattern.test(email)) {
                this.setState({ message: "Введен некоректный email!", modalIsOpen: false, show: true, redirect: false });
                return;
            }
        }

        if (activeTab == 2) {
            if (tel === "") {
                this.setState({ message: "Введен некоректный номер телефона", modalIsOpen: false, show: true, redirect: false });
                return;
            }
        }

        try {
            //TODO: Change this on backend
            const hashedPassword = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex);

            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email, tel: tel, password: hashedPassword })
            });

            const login = await response.json();

            if (login.status !== "OK") {
                this.setState({ message: login.status, type: login.type, show: true, email: "", tel: "", password: "", redirect: false });
            } else {
                (new User()).update(login.fullName);
                (new User()).updateType(login.type);
                (new Token()).update(login.token);

                window.location.href = '/';
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const { message, type, show, email, tel, password, activeTab, qrShow } = this.state;

        return (
            <div className="page-content container" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Вход"],
                ])} />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2>Новый клиент</h2>
                                <p><strong>Регистрация</strong></p>
                                <p>
                                    Создание учетной записи поможет покупать быстрее.</p>
                                <p>
                                    Вы сможете контролировать состояние заказа, а также просматривать заказы сделанные ранее.
                                </p>
                                <a href="/register/retail" className="btn btn-primary">Розничный</a>
                                &nbsp;
                                <a href="/register/wholesale" className="btn btn-primary">Оптовый</a>
                            </div>
                            <div className="col-sm-6">
                                <h2>Зарегистрированный клиент</h2>
                                <p><strong>Войти в личный кабинет</strong></p>

                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a aria-current="page" href="#" onClick={() => this.setActiveTab(1)} className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>По email</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" onClick={() => this.setActiveTab(2)} className={`nav-link ${activeTab === 2 ? 'active' : ''}`}>По телефону</a>
                                    </li>
                                    {qrShow && 
                                        <li className="nav-item">
                                            <a href="#" onClick={() => this.setActiveTab(3)} className={`nav-link ${activeTab === 3 ? 'active' : ''}`}>QR</a>
                                        </li>
                                    }
                                </ul>

                                <form onSubmit={this.handleLogin}>
                                    {activeTab === 1 && (
                                        <div className="form-group">
                                            <CheckEmail currentEmail = { email } className="form-control" onChange={(e) => {this.setState(prevState => ({email: e.target.value}))}}/>
                                        </div>
                                    )}
                                    {activeTab === 2 && (
                                        <div className="form-group">
                                            <Telphone tel={tel} onChange={(e) => this.setState({ tel: e.target.value })} />
                                        </div>
                                    )}
                                    {activeTab === 3 && (
                                        <div className="form-group">
                                           <img src="./images/qr.svg" style={{width: 200, height: 200}}/>
                                        </div>
                                    )}
                                    {((activeTab === 1) || (activeTab === 2)) && (
                                        <>
                                        <div className="form-group">
                                            <input type="password" value={password} placeholder="Пароль" className="form-control" onChange={(e) => this.setState({ password: e.target.value })} />
                                            <br />
                                            <a href="/forgotten">Забыли пароль?</a>
                                        </div>
                                        <input type="submit" value="Войти" className="btn btn-primary" onClick={this.openModal} />
                                        </>
                                    )}
                                    {show && <ModalWindow value={message} type={type} redirect={false} redirectUrl={"/"} onClose={() => this.setState({ show: false })} />}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
