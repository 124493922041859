import React, { Component } from 'react';

class ColumnLeft extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          values: new Map()
        };
    }
  
    componentDidMount() {
        this.setState({
            values: new Map([
                ["/catalog", "Каталог"],
                ["/garantiya", "Гарантия"],
                ["/dostavka", "Доставка"],
                ["/oplata", "Оплата"],
                ["/review", "Отзывы"],
            ])
        })
    }
  
    render() {
        return (
            <div className="footer-menu-col">
                <nav>

                    <ul className="menu">

                        {Array.from(this.state.values.entries()).map(([key, value]) => (

                            <li key={key}><a className="" href={key}>{value}</a></li>

                        ))}

                    </ul>
            
                </nav>
            </div>
        )
    }
}

export default ColumnLeft;