import React, { Component } from 'react';

import CartItem from './CartItem';
import Breadcrumb from './../../header/Breadcrumb';
import Telphone from './../../utils/Telphone';
import ModalText from './../../modal/ModalText';
import Format from "./../../utils/Format";
import {formatAmount} from "./../../utils/Format";
import Token from "./../../utils/Token";
import CheckEmail from '../../utils/CheckEmail';
import Pay from './../../enum/Pay';
import Delivery from './../../enum/Delivery';
import UserType from './../../enum/UserType';
import ModalWindow from './../../modal/ModalWindow'
import ClientType from '../../enum/ClientType';

class Cart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cart: {
                pay: Pay.UNDEFINED,
                tel: "",
                user: {},
                email: "",
                active: false,
                fullName: "",
                delivery: Delivery.UNDEFINED,
                deliveryAddress: "",
                items: [],
            },
            remainsMax: false,
            read: false,
            show: false,
            showPrice: false,
            message: "",
        };

        this.handleChangeRequisite = this.handleChangeRequisite.bind(this);

        this.handleOrderClick = this.handleOrderClick.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);
        this.handleUpdateCount = this.handleUpdateCount.bind(this);
    }

    async getCart() {
        try {
            const response = await fetch('/api/cart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const cart = await response.json();

            this.setState({ cart, read: cart.active });
        } catch (error) {
            console.error('Failed to fetch cart:', error);
        }
    }

    async componentDidMount() {
        const response = await fetch('/api/user/remains', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Token': (new Token()).get()
            }
        });

        this.setState({ remainsMax: response.ok });

        this.getCart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cart !== this.props.cart) {
            this.setState({
                cart: this.props.cart,
            });
        }
    }
    
    checkData () {
        let result = true;

        const { cart } = this.state;

        if (cart.user !== null) {
            if (cart.user.type == ClientType.LEGAL) {
                if (cart.email == "") {
                    this.setState({ show: true, message: "Заполните email", redirect: false });

                    result = false;
                }
            }
        }

        if (cart.tel == "") {
            this.setState({ show: true, message: "Заполните номер телефона", redirect: false });

            result = false;
        }

        if (cart.fullName == "") {
            this.setState({ show: true, message: "Заполните Ф. И. О.", redirect: false });

            result = false;
        }

        if (cart.user !== null) { 
            if (cart.user.type == UserType.UNDEFINED || cart.user.type  == UserType.RETAIL) {
                if (cart.delivery == Delivery.UNDEFINED) {
                    this.setState({ show: true, message: "Выберите способ доставки", redirect: false });

                    result = false;
                }

                if (cart.delivery == Delivery.AROUNDTOWN 
                    || cart.delivery == Delivery.AROUNDCOUNTRY ) {
                    if (cart.deliveryAddress == "") {
                        this.setState({ show: true, message: "Введите адрес доставки", redirect: false });
            
                        result = false;
                    }
                }
            }

            if (cart.user.type == UserType.WHOLESALE) {
                if (cart.delivery == Delivery.UNDEFINED && cart.deliveryAddress == "") {
                    this.setState({ show: true, message: "Выберите реквизиты", redirect: false });
            
                    result = false;
                } 
            }  
        } else {
            if (cart.delivery == Delivery.UNDEFINED) {
                this.setState({ show: true, message: "Выберите способ доставки", redirect: false });

                result = false;
            }

            if (cart.delivery == Delivery.AROUNDTOWN 
                || cart.delivery == Delivery.AROUNDCOUNTRY ) {
                if (cart.deliveryAddress == "") {
                    this.setState({ show: true, message: "Введите адрес доставки", redirect: false });
        
                    result = false;
                }
            }
        }

        if (cart.user !== null) {
            if (!cart.user.payNull) {
                if (cart.pay == Pay.UNDEFINED) {
                    this.setState({ show: true, message: "Выберите способ оплаты", redirect: false });
        
                    result = false;
                }
            } else if (cart.user.payNull) {
                cart.pay = Pay.PAYMENTPICKUP;

                this.setState({cart: cart});
            }
        }

        if (cart.pay == Pay.UNDEFINED) {
            if (!cart.user.payNull) {
                this.setState({ show: true, message: "Выберите способ оплаты", redirect: false });

                result = false;
            } else if (cart.user.payNull) {
                cart.pay = Pay.PAYMENTPICKUP;

                this.setState({cart: cart});
            }
        }

        if (cart.items.length == 0) {
            this.setState({ show: true, message: "Корзина пуста", redirect: false });

            result = false;
        }

        return result;
    }

    async handleOrderClick() {
        const { cart } = this.state;

        if (this.checkData()) {
            this.props.history.push({
                pathname: '/payment',
                state: { cart: cart },
            });
        }
    }

    handleCheckClick() {
        this.setState((prevState) => ({ read: !prevState.read }));
    }

    handleUpdateCount() {
        this.getCart();
    }

    renderDelivery() {
        const { cart } = this.state;

        return (
        <div>
            <div className="panel panel-default">
                <div className="panel-heading" style={{ marginBottom: 10 }}><b><span style={{color: "red"}}>*</span>&nbsp;Доставка</b></div>
                <div className="panel-body">
                    <div style={{paddingLeft: 25}}>
                        <div className="radio">
                            <label>
                                <input type="radio" name="shipping_method" value={ Delivery.PICKUP } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, delivery: e.target.value}}))}}/>
                                &nbsp;Самовывоз
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="shipping_method" value={ Delivery.AROUNDTOWN } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, delivery: e.target.value}}))}}/>
                                &nbsp;По городу Алматы <b>(Яндекс доставка)</b>
                            </label>
                            <div>
                                <div style={{ display: cart.delivery == Delivery.AROUNDTOWN ? "block" : "none" }}>
                                    <div className="form-group required">
                                        <label className="control-label" htmlFor="input-shipping-city">Адрес</label>
                                        <input type="text" name="city" value={ cart.deliveryAddress } className="form-control"  onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, deliveryAddress: e.target.value}}))}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="shipping_method"value={ Delivery.AROUNDCOUNTRY } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, delivery: e.target.value}}))}}/>
                                &nbsp;По городам Казахстана через транспортную компанию <b>(оплачивает покупатель)</b>
                            </label>
                            <div>
                                <div style={{ display: cart.delivery == Delivery.AROUNDCOUNTRY ? "block" : "none" }}>
                                    <div className="form-group required">
                                        <label className="control-label" htmlFor="input-shipping-city">Город и адрес</label>
                                        <input type="text" name="city" value = { cart.deliveryAddress } className="form-control"  onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, deliveryAddress: e.target.value}}))}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    renderPayment() {
        return (
        <div>
            <div className="panel panel-default">
                <div className="panel-heading" style={{ marginBottom: 10 }}><b><span style={{color: "red"}}>*</span>&nbsp;Оплата</b></div>
                <div className="panel-body" style={{paddingLeft: 25}}>
                    <div className="radio">
                        <label>
                            <input type="radio" name="payment_method" value={ Pay.CREDITCARD } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, pay: e.target.value}}))}}/>
                            <span style={{position: "relative", top: -4}}>&nbsp;<img src="./images/creditcard.png" style={{width: 20, height: 20}}/>&nbsp;</span>Кредитной картой
                        </label>
                    </div>

                    <div className="radio">
                        <label>
                            <input type="radio" name="payment_method" value={ Pay.KASPI }  onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, pay: e.target.value}}))}}/>
                            <span style={{position: "relative", top: -2}}>&nbsp;<img src="./images/kaspi/kaspi_qr.png" style={{width: 20, height: 20}}/>&nbsp;</span>Kaspi
                        </label>
                    </div>

                    <div className="radio">
                        <label>
                            <input type="radio" name="payment_method" value={ Pay.PAYMENTPICKUP } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, pay: e.target.value}}))}}/>
                            &nbsp;Оплата при самовывозе
                        </label>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    async handleChangeRequisite(item) {
        try {
            const token = (new Token()).get();
    
            const response = await fetch('/api/cart/recalculation/' + item.uuid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token
                }
            });
    
            if (response.ok) {
                await this.getCart(); 

                this.setState({showPrice: true});
            } else {
                console.error('Ошибка запроса:', response.statusText);
            }
    
            this.setState(prevState => ({
                cart: {
                    ...prevState.cart,
                    client: item.client,
                    deliveryAddress: item.address
                }
            }));
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    }

    render() {
        const { cart, read, show, showPrice, message, remainsMax } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Оформление заказа"], 
                    ])} />

                    <div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td className="text-center"></td>
                                        <td className="text-center"><b>Наименование</b></td>
                                        <td className="text-center"><b>Артикул</b></td>
                                        <td className="text-center" style={{minWidth: 110, maxWidth: 110}}><b>Количество</b></td>
                                        <td className="text-center" style={{minWidth: 110, maxWidth: 110}}><b>Цена</b></td>
                                        <td className="text-center" style={{minWidth: 110, maxWidth: 110}}><b>Всего</b></td>
                                        <td className="text-center" style={{maxWidth: 50}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart.items && cart.items.map((item, index) => (
                                        <CartItem key={index} value={item} remainsMax={remainsMax} onUpdateCount={this.handleUpdateCount}/>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{textAlign: 'right'}}>
                                        <strong>
                                            Итого:
                                        </strong>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        <Format value={cart.total}/>
                                        &nbsp;₸
                                    </td>
                                    <td></td>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div>
                                <div className="panel panel-default">
                                    <div className="panel-heading" style={{ marginBottom: 10 }}><b>Личные данные</b></div>
                                    <div className="panel-body">
                                        <div className="row">
                                            {cart.user && cart.user.type == UserType.WHOLESALE && 
                                                <>
                                                    <div className="form-group col-lg-12 required">
                                                        <label className="control-label"><span style={{color: "red"}}>*</span>&nbsp;Ф. И. О.</label>
                                                        <input type="text" required value={cart.fullName} className="form-control" onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, fullName: e.target.value}}))}}/>
                                                    </div>
                                                    <div className="form-group col-lg-6 required">
                                                        <label className="control-label"><span style={{color: "red"}}>*</span>&nbsp;Телефон</label>
                                                        <Telphone tel={cart.tel} onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, tel: e.target.value}}))}}/>
                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="control-label"><span style={{color: "red"}}>*</span>&nbsp;email</label>
                                                        <CheckEmail currentEmail = { cart.email } className="form-control" onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, email: e.target.value}}))}}/>    
                                                    </div>
                                                </>
                                            }
                                            {(!cart.user || cart.user.type == UserType.UNDEFINED || cart.user.type == UserType.RETAIL) && 
                                                <>
                                                    <div className="form-group col-lg-6 required">
                                                        <label className="control-label"><span style={{color: "red"}}>*</span>&nbsp;Ф. И. О.</label>
                                                        <input type="text" required value={cart.fullName} className="form-control" onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, fullName: e.target.value}}))}}/>
                                                    </div>
                                                    <div className="form-group col-lg-6 required">
                                                        <label className="control-label"><span style={{color: "red"}}>*</span>&nbsp;Телефон</label>
                                                        <Telphone tel={cart.tel} onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, tel: e.target.value}}))}}/>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">

                        </div>
                    </div>

                    {cart.user && cart.user.type == UserType.WHOLESALE && 
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading" style={{ marginBottom: 10 }}>
                                        <b>
                                            <span style={{color: "red"}}>
                                                *
                                            </span>
                                            &nbsp;Реквизиты
                                        </b>
                                    </div>
                                    <div style={{paddingLeft: 25}}>
                                        <div className="panel-body">
                                            <div>
                                                {cart.user.requisites && cart.user.requisites.map((item, index) => (
                                                    <div className="radio" key={index}>
                                                        <input type="radio" name="legal_address" value={ item.company } onChange={() => this.handleChangeRequisite(item)} />
                                                        <label>
                                                            &nbsp;
                                                            {item.type === ClientType.INDIVIDUAL ? (
                                                                <>
                                                                    <span>Физ. лицо:&nbsp;</span>
                                                                    {cart.user.fullName}&nbsp;&nbsp;
                                                                    <span style={{ color: item.balance > 0 ? "red" : "green" }}>
                                                                        {item.balance > 0 ? "Долг:" : "Баланс:"}
                                                                    </span>
                                                                </>
                                                            ) : item.type === ClientType.LEGAL ? (
                                                                <>
                                                                    <span>Юр. лицо:&nbsp;</span>
                                                                    {item.company + ` [БИН/ИИН: ${item.bin}]`}&nbsp;&nbsp;
                                                                    <span style={{ color: item.balance > 0 ? "red" : "green" }}>
                                                                        {item.balance > 0 ? "Долг:" : "Баланс:"}
                                                                    </span>
                                                                </>
                                                            ) : null}
                                                            <span style={{color: item.balance <= 0 ? "green" : "red"}}>
                                                                {` ${formatAmount(Math.abs(item.balance))}` }&nbsp;₸
                                                            </span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <br/>
                                        </div>
                                        <div className="form-group required">
                                            <label className="control-label" htmlFor="input-shipping-city"><b><span style={{color: "red"}}>*</span>&nbsp;Адрес доставки</b></label>
                                            <input type="text" name="city" value = { cart.deliveryAddress } className="form-control"  onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, deliveryAddress: e.target.value}}))}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-sm-12">
                            {cart.user && (cart.user.type == UserType.RETAIL || cart.user.type == UserType.UNDEFINED) && 
                                this.renderDelivery()
                            }
                            {!cart.user && 
                                this.renderDelivery()
                            }
                            <br/>
                            { cart.user && !cart.user.payNull &&
                                this.renderPayment()
                            }
                            {!cart.user && 
                                this.renderPayment()
                            }
                            <div>
                                <h2>Комментарий к заказу</h2>
                                <div className="form-group">
                                    <textarea name="comment" rows="2" className="form-control" value={ cart.comment } onChange={(e) => {this.setState(prevState => ({cart: {...prevState.cart, comment: e.target.value}}))}}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="pull-right" style={{ width: "100%" }}>
                            <input type="checkbox" checked={ read } onClick={this.handleCheckClick} />
                            &nbsp;
                            <label htmlFor="input-agree" className="control-label">
                                Мною прочитаны <b><ModalText title={ "условия соглашения" } fileName="PrivacyPolicy.txt"></ModalText></b>
                            </label>
                            <br />
                            <span style={{color: "red"}}><b>Поля отмеченные * обязательны к заполнению/выбору</b></span>
                            <br />
                            <br />
                            <input
                                type="button"
                                disabled={ cart.items.length == 0 || !read }
                                value="Оформить заказ"
                                data-value="Оформить заказ"
                                className="btn btn-primary"
                                style={{ display: "block!important" }}
                                onClick={ this.handleOrderClick }
                            />
                        </div>
                    </div>
                </div>
                {show && <ModalWindow value={message} redirect={false} onClose={() => this.setState({ show: false })} />}
                {showPrice && <ModalWindow value={"Цены пересчитаны!"} redirect={false} onClose={() => this.setState({ showPrice: false })} />}
            </div>
        );
    }
}

export default Cart;
