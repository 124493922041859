import React, { Component } from 'react';

class Service extends Component {
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
            height: '100vh', 
            textAlign: 'center', 
            fontSize: '24px', 
        };

        return (
            <div style={containerStyle}>
                <img src="./images/service.jpg" alt="" />
            </div>
        );
    }
}

export default Service;
