import React from 'react';

const CheckEmail = ({ currentEmail, onChange, className }) => {
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]{1,100}@[a-zA-Z0-9.-]{1,100}\.[a-zA-Z]{1,10}$/;
    return emailRegex.test(email);
  };

  const isValid = currentEmail.length === 0 || validateEmail(currentEmail);

  return (
    <div>
      <input
        type="email"
        value={currentEmail}
        className={className}
        onChange={onChange}
        placeholder="example@mail.com"
      />
      {!isValid && <p style={{ color: 'red' }}>Введен некоректный email</p>}
    </div>
  );
};

export default CheckEmail;
