import React from 'react'

import Map from './Map'

export default function SectionContacts() {
    return (
        <div className="section-contacts">
            <div className="section-contacts__top">
                <div className="container">

                    <div className="contacts-row">
                        <div className="contact-item">

                            <div className="contact-item__icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2ZM12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.2801 9.72 20.2801 11.92C20.2801 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11005 19.76 7.85005 19L7.55005 18.83L4.43005 19.65L5.26005 16.61L5.06005 16.29C4.24005 15 3.80005 13.47 3.80005 11.91C3.81005 7.37 7.50005 3.67 12.05 3.67ZM8.53005 7.33C8.37005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.00005 8.5 7.00005 9.71C7.00005 10.93 7.89005 12.1 8.00005 12.27C8.14005 12.44 9.76005 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77005 11.64 9.62005 11.39C9.50005 11.15 9.61005 11 9.73005 10.89C9.84005 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77005 8.26 9.56005 7.77C9.36005 7.29 9.16005 7.35 9.00005 7.34C8.86005 7.34 8.70005 7.33 8.53005 7.33Z" fill="#3A9681"></path>
                                </svg>
                            </div>
                            <div className="contact-item__content">
                                <a href="tal:+7 (771) 218 11 02">+7 (771) 218 11 02</a>
                                <a href="tel:+7 (727) 390 91 91">+7 (727) 390 91 91</a>
                            </div>

                        </div>

                        <div className="contact-item">

                            <div className="contact-item__icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5ZM12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2ZM12 4C10.6739 4 9.40215 4.52678 8.46447 5.46447C7.52678 6.40215 7 7.67392 7 9C7 10 7 12 12 18.71C17 12 17 10 17 9C17 7.67392 16.4732 6.40215 15.5355 5.46447C14.5979 4.52678 13.3261 4 12 4Z" fill="black"></path>
                                </svg>
                            </div>
                            <div className="contact-item__content">
                                г. Алматы, ул. Емцова 26а<br />
                                (по пр. Рыскулова, напротив ТЦ "Бакорда")
                            </div>

                        </div>

                        <div className="contact-item">

                            <div className="contact-item__icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5ZM12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2ZM12 4C10.6739 4 9.40215 4.52678 8.46447 5.46447C7.52678 6.40215 7 7.67392 7 9C7 10 7 12 12 18.71C17 12 17 10 17 9C17 7.67392 16.4732 6.40215 15.5355 5.46447C14.5979 4.52678 13.3261 4 12 4Z" fill="black"></path>
                                </svg>
                            </div>
                            <div className="contact-item__content">
                                г. Алматы, пр. Райымбека, 257а
                            </div>

                        </div>
                        <div className="contact-item">

                            <div className="contact-item__icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5ZM12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2ZM12 4C10.6739 4 9.40215 4.52678 8.46447 5.46447C7.52678 6.40215 7 7.67392 7 9C7 10 7 12 12 18.71C17 12 17 10 17 9C17 7.67392 16.4732 6.40215 15.5355 5.46447C14.5979 4.52678 13.3261 4 12 4Z" fill="black"></path>
                                </svg>
                            </div>
                            <div className="contact-item__content">
                                г. Алматы, ул. Жамбыла, 204, 1 этаж
                            </div>

                        </div>


                        <div className="contact-item">

                            <div className="contact-item__icon">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_857_6)">
                                        <path d="M11.5287 10.0012L9.15839 8.22346V4.60227C9.15839 4.23816 8.86408 3.94385 8.49997 3.94385C8.13586 3.94385 7.84155 4.23816 7.84155 4.60227V8.55271C7.84155 8.76009 7.939 8.95566 8.10492 9.07944L10.7385 11.0547C10.8522 11.1403 10.9906 11.1865 11.1329 11.1863C11.3337 11.1863 11.5312 11.0961 11.6603 10.9223C11.879 10.6319 11.8197 10.2191 11.5287 10.0012Z" fill="black"></path>
                                        <path d="M8.5 0C3.81281 0 0 3.81281 0 8.5C0 13.1872 3.81281 17 8.5 17C13.1872 17 17 13.1872 17 8.5C17 3.81281 13.1872 0 8.5 0ZM8.5 15.6832C4.5397 15.6832 1.3168 12.4603 1.3168 8.5C1.3168 4.5397 4.5397 1.3168 8.5 1.3168C12.461 1.3168 15.6832 4.5397 15.6832 8.5C15.6832 12.4603 12.4603 15.6832 8.5 15.6832Z" fill="black"></path>
                                    </g>
                                    <defs>
                                        <clipPath>
                                            <rect width="17" height="17" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="contact-item__content">
                                Пн-пт: 9:30-18:00,<br />
                                Сб: 9:30-16:00
                            </div>

                        </div>

                        <div className="contact-item">
                            <div className="contact-item__social">
                                <a href="https://www.instagram.com/fealmaty/">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.4003 2.66663H21.6003C25.867 2.66663 29.3337 6.13329 29.3337 10.4V21.6C29.3337 23.651 28.5189 25.618 27.0686 27.0683C25.6183 28.5185 23.6513 29.3333 21.6003 29.3333H10.4003C6.13366 29.3333 2.66699 25.8666 2.66699 21.6V10.4C2.66699 8.34895 3.48175 6.38195 4.93203 4.93167C6.38231 3.48139 8.34932 2.66663 10.4003 2.66663ZM10.1337 5.33329C8.86062 5.33329 7.63972 5.83901 6.73955 6.73918C5.83937 7.63935 5.33366 8.86025 5.33366 10.1333V21.8666C5.33366 24.52 7.48033 26.6666 10.1337 26.6666H21.867C23.14 26.6666 24.3609 26.1609 25.2611 25.2607C26.1613 24.3606 26.667 23.1397 26.667 21.8666V10.1333C26.667 7.47996 24.5203 5.33329 21.867 5.33329H10.1337ZM23.0003 7.33329C23.4424 7.33329 23.8663 7.50889 24.1788 7.82145C24.4914 8.13401 24.667 8.55793 24.667 8.99996C24.667 9.44199 24.4914 9.86591 24.1788 10.1785C23.8663 10.491 23.4424 10.6666 23.0003 10.6666C22.5583 10.6666 22.1344 10.491 21.8218 10.1785C21.5093 9.86591 21.3337 9.44199 21.3337 8.99996C21.3337 8.55793 21.5093 8.13401 21.8218 7.82145C22.1344 7.50889 22.5583 7.33329 23.0003 7.33329ZM16.0003 9.33329C17.7684 9.33329 19.4641 10.0357 20.7144 11.2859C21.9646 12.5362 22.667 14.2318 22.667 16C22.667 17.7681 21.9646 19.4638 20.7144 20.714C19.4641 21.9642 17.7684 22.6666 16.0003 22.6666C14.2322 22.6666 12.5365 21.9642 11.2863 20.714C10.036 19.4638 9.33366 17.7681 9.33366 16C9.33366 14.2318 10.036 12.5362 11.2863 11.2859C12.5365 10.0357 14.2322 9.33329 16.0003 9.33329ZM16.0003 12C14.9395 12 13.922 12.4214 13.1719 13.1715C12.4218 13.9217 12.0003 14.9391 12.0003 16C12.0003 17.0608 12.4218 18.0782 13.1719 18.8284C13.922 19.5785 14.9395 20 16.0003 20C17.0612 20 18.0786 19.5785 18.8288 18.8284C19.5789 18.0782 20.0003 17.0608 20.0003 16C20.0003 14.9391 19.5789 13.9217 18.8288 13.1715C18.0786 12.4214 17.0612 12 16.0003 12Z" fill="black"></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="contact-item__social">
                                <a href="https://www.facebook.com/fealmaty">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0003 2.71997C8.66699 2.71997 2.66699 8.70664 2.66699 16.08C2.66699 22.7466 7.54699 28.28 13.9203 29.28V19.9466H10.5337V16.08H13.9203V13.1333C13.9203 9.78664 15.907 7.94664 18.9603 7.94664C20.4137 7.94664 21.9337 8.19997 21.9337 8.19997V11.4933H20.2537C18.6003 11.4933 18.0803 12.52 18.0803 13.5733V16.08H21.787L21.187 19.9466H18.0803V29.28C21.2222 28.7838 24.0833 27.1807 26.1469 24.7601C28.2105 22.3394 29.3408 19.2608 29.3337 16.08C29.3337 8.70664 23.3337 2.71997 16.0003 2.71997Z" fill="black"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="section-map">
                    <div style={{minHeight: 400}}>
                        <Map />
                    </div>
                </div>
            </div>
        </div>
    )
}
