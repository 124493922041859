import React, { Component } from 'react';

class Email extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      const response = await fetch('/api/register/email/' + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === "OK") {
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Failed to fetch email:', error);
    }
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default Email;
