import React, { Component } from 'react';

import { withRouter } from 'react-router-dom'; 
import Token from "./../utils/Token";

class HalykBank extends Component {

    constructor(props) {
        super(props);

        // TEST
        /*
        this.state = {
            cart: this.props.cart,
            TerminalID: "67e34d63-102f-4bd1-898e-370781d0074d",
            ClientID: "test",
            ClientSecret: "yF587AV9Ms94qN2QShFzVR3vFnWkhjbAK3sG",
            url: "https://testoauth.homebank.kz/epay2/oauth2/token",
        };
        */

        // PROD
        this.state = {
            cart: this.props.cart,
            TerminalID: "3de6a3f2-a687-4e68-9c0d-aab2d1fab3e0",
            ClientID: "FE.KZ",
            ClientSecret: "7CIuiYrN3qjPX$jj",
            url: "https://epay-oauth.homebank.kz/oauth2/token",
        };
    }

    loadPaymentScript = () => {
        const script = document.createElement('script');
        
        // TEST
        //script.src = "https://test-epay.homebank.kz/payform/payment-api.js";

        // PROD
        script.src = "https://epay.homebank.kz/payform/payment-api.js";

        script.async = true;
        script.onload = () => {
            this.makePayment('widget');
        };
        script.onerror = () => {
            console.error('Failed to load Halyk Bank Payment API script.');
        };
        document.body.appendChild(script);
    };

    handleWidgetPayment = async (event) => {
        event.preventDefault();
        await this.makePayment();
    };

    makePayment = async () => {
        const { cart, ClientID, ClientSecret, TerminalID, url } = this.state;

        const data = new URLSearchParams();
        data.append('grant_type', 'client_credentials');
        data.append('scope', 'payment usermanagement');
        data.append('client_id', ClientID);
        data.append('client_secret', ClientSecret);
        data.append('invoiceID', cart.payNumber);
        data.append('amount', cart.amount);
        data.append('currency', 'KZT');
        data.append('terminal', TerminalID);

        const options = {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const auth = await response.json();

            const paymentObject = this.createPaymentObject(auth, cart.payNumber, cart.amount);

            if (window.halyk) {
                halyk.showPaymentWidget(paymentObject, this.callBk);
            } else {
                console.error('Halyk API is not available');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    createPaymentObject = (auth, invoiceId, amount) => {
        const { TerminalID } = this.state;

        return {
            invoiceId,
            invoiceIdAlt: invoiceId,
            backLink: "/",
            failureBackLink: "/error",
            postLink: "/checkout",
            failurePostLink: "/error",
            language: "RUS",
            description: "Оплата в интернет магазине",
            terminal: TerminalID,
            amount,
            currency: "KZT",
            auth,
        };
    };

    callBk = (result) => {
        const { cart } = this.state;

        if (result.success) {
            fetch('/api/cart/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify(cart)
            })
            .then(responseCheckout => {
                console.log(responseCheckout);
        
                if (responseCheckout.ok) {
                    this.props.history.push("/checkout");
                } else {
                    return responseCheckout.text().then(errorMessage => {
                        console.error('Checkout failed:', errorMessage);
                    });
                }
            })
            .catch(error => {
                this.props.history.push({
                    pathname: "/error",
                    state: { error: "Payment failed due to an unknown error." } 
                });
                console.error('Error during checkout:', error);
            });
        } else {
            this.props.history.push({
                pathname: "/error",
                state: { error: "Payment failed due to an unknown error." } 
            });
        }
    };

    componentDidMount() {
        this.loadPaymentScript();
    }

    render() {
        return <></>;
    }
}

export default withRouter(HalykBank);