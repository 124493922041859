import React, { useState, useEffect } from 'react';

const ToastNotification = ({ text, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [text, onClose]);

  return (
    isVisible && (
      <div className="toast-container">
        <div className="toast toast-success">
          <div className="toast-body">
            <div className="toast-icon">
              <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
              </svg>
            </div>
            <span>{text}</span>
          </div>
          <button className="toast-close" onClick={() => { setIsVisible(false); onClose(); }}>
            <svg viewBox="0 0 14 16" width="14" height="14">
              <path fillRule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path>
            </svg>
          </button>
        </div>
      </div>
    )
  );
};

export default ToastNotification;
