import React, { Component } from 'react';

import Modal from 'react-modal';

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";
import ModalThanks from "./../../modal/ModalThanks";

class Questionnaire extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            modalIsOpen: false,
            showThankYou: false,
            values: {
                companyName: "",
                foundationYear: "",
                legalAddress: "",
                physicalAddress: "",
                contactPerson: "",
                phone: "",
                email: "",
                carBrands: "",
                facilities: "",
                salesChannels: "",
                salesVolume: "До 5 000 000 тенге",
                marketingTools: "",
                customerBase: "",
                expectations: "",
                warrantyHandling: "",
                comments: "",
                questions: "",
            },
        };
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal = () => {
        if (this.subtitle) {
            this.subtitle.style.color = '#f00';
        }
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleSend = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('/api/questionnaire', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state.values)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            this.setState({ modalIsOpen: false, showThankYou: true });
            window.location.href = '/';
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    
    customStyles = {
        content: {
            marginTop: "60px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '100%',
            maxWidth: 800,
            transform: 'translate(-50%, -50%)',
        },
    };

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            values: {
                ...prevState.values,
                [name]: value,
            },
        }));
    };

    render() {
        const { title, modalIsOpen, showThankYou } = this.state;

        return (
            <>
                <button className="btn btn-primary" onClick={this.openModal}>{title}</button>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={this.customStyles}
                    contentLabel="" 
                >
                    <div className="modal-header">
                        <h2 className="modal-title">Анкета</h2>
                    </div>
                    <div className="modal-body">
                        <div className="container" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {this.renderForm()}
                        </div>
                    </div>
                </Modal>
                {showThankYou && <ModalThanks onClose={() => this.setState({ showThankYou: false })} />}
            </>
        )
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSend}>
                <div className="d-flex justify-content-end mt-3">
                    <button type="submit" className="btn btn-primary">Отправить</button>
                </div>

                {this.renderInput('companyName', 'Название вашей компании')}
                {this.renderInput('foundationYear', 'Год основания')}
                {this.renderInput('legalAddress', 'Юридический адрес')}
                {this.renderInput('physicalAddress', 'Физический адрес (если отличается)')}
                {this.renderInput('contactPerson', 'Контактное лицо')}
                {this.renderInput('phone', 'Телефон')}
                {this.renderInput('email', 'Электронная почта', '')}
                {this.renderInput('carBrands', 'Для каких марок автомобилей вы специализируетесь на продаже запасных частей')}
                {this.renderInput('facilities', 'Какие торговые и складские площади вы имеете')}
                {this.renderInput('salesChannels', 'Какие каналы продаж вы используете (розничная торговля, интернет-магазин, оптовая продажа и т. д.)')}
                {this.renderSelect('salesVolume', 'Каковы ваши текущие объемы продаж в месяц автомобильных запасных частей (по обороту)', [
                    'До 5 000 000 тенге',
                    'Свыше 5 000 000 тенге',
                    'Свыше 10 000 000 тенге'
                ])}
                {this.renderInput('marketingTools', 'Какие инструменты маркетинга вы применяете для привлечения клиентов и продвижения запасных частей')}
                {this.renderInput('customerBase', 'Какова ваша клиентская база (автосервисы, автомагазины, частные владельцы и т. д.)')}
                {this.renderInput('expectations', 'Каковы ваши ожидания от сотрудничества с нами (условия поставки, скидки, маркетинговая поддержка и т. д.)')}
                {this.renderInput('warrantyHandling', 'Каким образом вы решаете вопросы гарантийного обслуживания или возвратов для своих клиентов')}
                {this.renderInput('comments', 'Есть ли у вас какие-либо комментарии, которые вы хотели бы добавить')}
                {this.renderInput('questions', 'Вы можете задать интересующие вас вопросы')}
            </form>
        );
    }

    renderInput(name, label, type = 'text') {
        if (name == "email") {
            return (
                <div className="form-group mt-3">
                    <label htmlFor={name}>{label}</label>
                    <CheckEmail currentEmail={""} id={name} name={name} className="form-control" onChange={this.onChange}/>
                </div>
            );
        }
        else if (name == "phone") {
            return (
                <div className="form-group mt-3">
                    <label htmlFor={name}>{label}</label>
                    <Telphone tel={name} id={name} name={name} onChange={this.onChange}/>
                </div>
            );
        } else {
            return (
                <div className="form-group mt-3">
                    <label htmlFor={name}>{label}</label>
                    <input type={type} className="form-control" id={name} name={name} onChange={this.onChange}/>
                </div>
            );
        }
    }

    renderSelect(name, label, options) {
        return (
            <div className="form-group mt-3">
                <label htmlFor={name}>{label}</label>
                <select className="form-control" id={name} name={name} onChange={this.onChange}>
                    {options.map((option, index) => (
                        <option key={option} value={option} selected={index === 0}>{option}</option>
                    ))}
                </select>
            </div>
        );
    }
}

export default Questionnaire;
