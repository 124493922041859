import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Telphone from "./../../utils/Telphone";
import CheckEmail from "./../../utils/CheckEmail";
import ModalWindow from "./../../modal/ModalWindow";

import Format from "./../../utils/Format";
import FormattedDate from "./../../utils/FormattedDate";

import Token from "./../../utils/Token";

import Breadcrumb from "./../../header/Breadcrumb";

import ClientType from "./../../enum/ClientType";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);
setDefaultLocale("ru");

class AccountWholesale extends Component {
    state = {
        show: false,
        message: "",
        redirect: true,
        modalIsOpen: false,
        activeTab: 1,
        startDate: this.formatDate(new Date()),
        endDate: this.formatDate(new Date()),
        setStartDate: new Date(),
        setEndDate: new Date(),
        history: [],
        review: "",
        data: {
            tel: "",
            email: "",
            confirm: "",
            fullName: "",
            password: "",
            requisites: [{ uuid: '', type: ClientType.INDIVIDUAL, company: '', bin: '', address: '', balance: 0 }]
        }
    };

    formatDate(date) {
        if (!date) {
            return '';
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    componentDidMount() {
        this.loadUserData();
        this.loadUserHistory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    updateData = (key, value) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [key]: value
            }
        }));
    }

    loadUserData = async () => {
        try {
            const response = await fetch(`/api/user/${new Token().get()}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
            const result = await response.json();
            if (result) {
                this.setState({
                    message: result.status,
                    show: !result.active,
                    redirect: !result.active,
                    data: {
                        tel: result.tel,
                        email: result.email,
                        fullName: result.fullName,
                        requisites: result.requisites || [{ uuid: '', type: ClientType.INDIVIDUAL, company: '', bin: '', address: '' }]
                    }
                });
            }
        } catch (error) {
            this.setState({ show: true, message: 'There was a problem loading user data.' });
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    formatDateString(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}-${month}-${year}`;
    }

    loadUserHistory = async () => {
        const { startDate, endDate } = this.state;

        try {
            var body = JSON.stringify({ begin: startDate, end: endDate });

            const response = await fetch(`/api/user/wholesale/history/${new Token().get()}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            });

            const result = await response.json();

            if (response.ok) {
                if (result) {
                    this.setState({ history: result });
                }
            } else {
                this.setState({ history: [] });
            }
        } catch (error) {
            this.setState({ history: [] });
            this.setState({ show: true, message: 'There was a problem loading user history.' });
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    handleSend = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/user/wholesale/update/${new Token().get()}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state.data)
            });
            const result = await response.json();
            this.setState({ message: result.message, show: true, redirect: result.value });
        } catch (error) {
            this.setState({ show: true, message: 'There was a problem updating user data.' });
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    handleSendReview = async (event) => {
        event.preventDefault();
        const { review } = this.state;
        if (review.length > 0) {
            try {
                const response = await fetch(`/api/review/add/${new Token().get()}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ review })
                });
                const result = await response.json();
                this.setState({ review: "", message: result.message, show: true, redirect: false });
            } catch (error) {
                this.setState({ show: true, message: 'There was a problem sending review.' });
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    handleChange = (field, value) => {
        if (field === 'review') {
            this.setState({ review: value });
        } else {
            this.updateData(field, value);
        }
    }

    handleStartDateChange = (date) => {
        this.setState({ setStartDate: new Date(date) });

        this.setState({ startDate: this.formatDate(date) }, () => {
            this.loadUserHistory();
        });
    }

    handleEndDateChange = (date) => {
        this.setState({ setEndDate: new Date(date) });

        this.setState({ endDate: this.formatDate(date) }, () => {
            this.loadUserHistory();
        });
    }

    handleRequisiteChange = (index, key, value) => {
        const newRequisites = Array.isArray(this.state.data.requisites) ? [...this.state.data.requisites] : [];
        newRequisites[index][key] = value;
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: newRequisites
            }
        }));
    };

    handleBinChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            this.handleRequisiteChange(index, 'bin', value);
        }
    }

    addRequisite = () => {
        const newRequisites = Array.isArray(this.state.data.requisites) ? [...this.state.data.requisites] : [];
        newRequisites.push({ uuid: '', type: ClientType.INDIVIDUAL, company: '', bin: '', address: '' });
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: newRequisites
            }
        }));
    };

    removeRequisite = (index) => {
        const newRequisites = Array.isArray(this.state.data.requisites) ? this.state.data.requisites.filter((_, i) => i !== index) : [];
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                requisites: newRequisites
            }
        }));
    };

    formatBalance = (balance) => {
        if (balance < 0) {
            return <b style={{ color: "green" }}><Format value={-balance} />&nbsp;₸</b>;
        } else if (balance > 0) {
            return <b style={{ color: "red" }}><Format value={balance} />&nbsp;₸</b>;
        } else {
            return <b>0&nbsp;₸</b>;
        }
    };

    render() {
        const { data, show, message, redirect, activeTab, setStartDate, setEndDate, review } = this.state;

        return (
            <div className="page-content container" style={{ padding: 130, position: "relative", marginBottom: 75, minHeight: 600, boxSizing: "border-box" }}>
                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Личный кабинет"],
                ])} />
                <div className="row">
                    <div className="col-sm-12">
                        <h1>Личный кабинет</h1>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="#" onClick={() => this.setActiveTab(1)} className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>Данные</a>
                            </li>
                            <li className="nav-item">
                                <a href="#" onClick={() => this.setActiveTab(2)} className={`nav-link ${activeTab === 2 ? 'active' : ''}`}>История заказов</a>
                            </li>
                        </ul>
                        {activeTab === 1 && (
                            <>
                                <form onSubmit={this.handleSend}>
                                    <br />
                                    <div className='d-flex justify-content-between'>
                                        <div></div>
                                        <div>
                                            <button type="submit" className="btn btn-primary">Сохранить</button>
                                            {show && <ModalWindow value={message} redirect={redirect} redirectUrl={"/"} onClose={() => this.setState({ show: false })} />}
                                        </div>
                                    </div>
                                    <br />
                                    <fieldset>
                                        <div className='row'>
                                            <div className="col-12">
                                                <label className="label" htmlFor="input-firstname"><span style={{ color: "red" }}>*</span>&nbsp;<b>Ф. И. О.</b></label>
                                                <input type="text" value={data.fullName} placeholder="Ф. И. О." className="form-control" onChange={(e) => this.handleChange('fullName', e.target.value)} />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className="col-6">
                                                <label className="label" htmlFor="input-email"><span style={{ color: "red" }}>*</span>&nbsp;<b>email</b></label>
                                                <CheckEmail currentEmail={data.email} className="form-control" onChange={(e) => this.handleChange('email', e.target.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label className="label" htmlFor="input-telephone"><span style={{ color: "red" }}>*</span>&nbsp;<b>Телефон</b></label>
                                                <Telphone tel={data.tel} onChange={(e) => this.handleChange('tel', e.target.value)} />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <br />
                                    <fieldset>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <legend>Реквизиты</legend>
                                            </div>
                                            <div>
                                                <button type="button" className='btn btn-primary' onClick={this.addRequisite}>Добавить реквизиты</button>
                                            </div>
                                        </div>
                                        {data.requisites && data.requisites.map((item, index) => {
                                            const { type, company, bin, address } = item;
                                            return (
                                                <>
                                                    <div className='row'>
                                                        <div className='col-2' style={{ height: 40 }}>
                                                            <select value={type} onChange={(e) => this.handleRequisiteChange(index, 'type', e.target.value)} style={{ width: "100%", margin: 10, height: 40 }}>
                                                                <option value={ClientType.LEGAL}>Юр. лицо</option>
                                                                <option value={ClientType.INDIVIDUAL}>Физ. лицо</option>
                                                            </select>
                                                        </div>
                                                        {type === ClientType.LEGAL && (
                                                            <>
                                                                <div className='col-2'>
                                                                    <input style={{ position: 'absolute', top: 10, width: "99%", height: 40 }}
                                                                        type="text"
                                                                        value={item.company}
                                                                        title="Наименование компании"
                                                                        placeholder="Наименование компании"

                                                                        onChange={(e) => this.handleRequisiteChange(index, 'company', e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className='col-2'>
                                                                    <input style={{ position: 'absolute', top: 10, width: "99%", height: 40 }}
                                                                        type="text"
                                                                        value={item.bin}
                                                                        title="БИН/ИИН"
                                                                        placeholder="БИН/ИИН"

                                                                        onChange={(e) => this.handleBinChange(index, e.target.value)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className={type === ClientType.LEGAL ? "col-3" : "col-7"}>
                                                            <input style={{ position: 'absolute', top: 10, width: "100%", height: 40 }}
                                                                type="text"
                                                                value={item.address}
                                                                title="Адрес доставки"
                                                                placeholder="Адрес доставки"

                                                                onChange={(e) => this.handleRequisiteChange(index, 'address', e.target.value)}
                                                            />
                                                        </div>
                                                        <div className='col-2 text-center'>
                                                            <span style={{ position: 'absolute', top: 20, height: 40 }}>
                                                                {this.formatBalance(item.balance)}
                                                            </span>
                                                        </div>
                                                        <div className='col-1'>
                                                            <span style={{ position: 'absolute', top: 20, height: 40 }}>
                                                                <img
                                                                    src="./../../images/icons/remove.png"
                                                                    style={{ cursor: 'pointer', marginLeft: 20, width: 20, height: 20 }}
                                                                    onClick={() => this.removeRequisite(index)}
                                                                    alt="delete"
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </fieldset>
                                    <br />
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Пароль</b></label>
                                                <input type="password" value={data.password} placeholder="Пароль" className="form-control" onChange={(e) => this.handleChange('password', e.target.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label className="label"><span style={{ color: "red" }}>*</span>&nbsp;<b>Подтверждение пароля</b></label>
                                                <input type="password" value={data.confirm} placeholder="Подтверждение пароля" className="form-control" onChange={(e) => this.handleChange('confirm', e.target.value)} />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <br />
                                </form>

                                <form onSubmit={this.handleSendReview}>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="label"><b>Отзыв</b></label>
                                                <textarea name="review" rows="2" className="form-control" value={review} onChange={(e) => this.handleChange('review', e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <br />
                                    <div className='d-flex justify-content-between'>
                                        <div></div>
                                        <div>
                                            <button type="submit" className="btn btn-primary">Оставить отзыв</button>
                                            {show && <ModalWindow value={message} redirect={false} onClose={() => this.setState({ show: false })} />}
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                        {activeTab === 2 && (
                            <div>
                                <br />
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="start-date">Начало периода:&nbsp;</label>
                                        <DatePicker
                                            id="start-date"
                                            showIcon={true}
                                            selected={setStartDate}
                                            onChange={this.handleStartDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            style={this.style}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="end-date">Конец периода:&nbsp;</label>
                                        <DatePicker
                                            id="end-date"
                                            showIcon={true}
                                            selected={setEndDate}
                                            onChange={this.handleEndDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            style={this.style}
                                        />
                                    </div>
                                </div>
                                <br />
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td className="text-center"><b>Номер</b></td>
                                            <td className="text-center"><b>Дата</b></td>
                                            <td className="text-center"><b>Сумма</b></td>
                                            <td className="text-center"><b>Статус</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.history.map((value, index) => (
                                            <tr key={index}>
                                                <td className="text-center"><a href={"/document/" + value.id}>{value.number}</a></td>
                                                <td className="text-center"><a href={"/document/" + value.id}><FormattedDate isoString={value.date} /></a></td>
                                                <td className="text-right"><a href={"/document/" + value.id}><Format value={value.amount} /></a></td>
                                                {value.status == "ASSEMBLY" &&
                                                    <td className="text-center"><a href={"/document/" + value.id}>НА СБОРКЕ</a></td>
                                                }
                                                {value.status == "CANCELLED" &&
                                                    <td className="text-center"><a href={"/document/" + value.id}>ОТМЕНЕН</a></td>
                                                }
                                                {value.status == "COMPLETED" &&
                                                    <td className="text-center"><a href={"/document/" + value.id}>ЗАВЕРШОН</a></td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountWholesale;
