import React from 'react'

import Breadcrumb from '../../header/Breadcrumb'

export default function Oplata() {
    return (
        <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
            <div className="container">

                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Оплата"],
                ])} />

                <div className="row">
                    <div className="col-sm-12">

                        <h1 className="page-content__title section-title">
                            Оплата
                        </h1>

                        <div className="page-content__description">

                            <ul className="pay_className--ul">

                                <li>
                                    <div className="pay_className--title">
                                        Наличными в филиалах по г. Алматы
                                    </div>
                                    <div className="pay_className--desc">&nbsp;</div>
                                </li>
                                <li>
                                    <div className="pay_className--title">
                                        Банковской картой (Visa,
                                        MasterCard, American Express, a
                                        также дебетные электронные карты)
                                    </div>
                                    <div className="pay_className--desc">
                                        Оплата производится по защищенному каналу через систему Авторизации "Jetpay"
                                    </div>
                                    <div className="pay_className--desc">&nbsp;</div>
                                </li>
                                <li>
                                    <div className="pay_className--title">
                                        Банковским переводом на наш расчетный счет в АО "Банк ЦентрКредит"
                                    </div>
                                    <div className="pay_className--desc">
                                        После оформления Вашего заказа онлайн и обязательного резервирования товара. Счет на оплату создается менеджерами магазина и будут высланы по реквизитам, указанным на Интернет-сайте.
                                    </div>
                                    <div className="pay_className--desc">&nbsp;</div>
                                </li>
                                <li>
                                    <div className="pay_className--title">
                                        Kaspi, Kaspi Red в филиалах по г.Алматы
                                    </div>
                                </li>

                            </ul>

                            <br/><br/>

                            <div className="row">

                                <div className="col-sm-12">

                                    <h2 className="page-content__title section-title">
                                        Возврат
                                    </h2>
                                </div>

                                <div className="col-sm-6 advantage-col delivery-col">
                                    <div className="advantage-item">
                                        <div className="advantage-item__icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M42.8175 16.6275L46.0125 13.4325C45 12.285 43.9875 11.25 42.84 10.26L39.645 13.5C36.1575 10.665 31.77 9 27 9C15.8175 9 6.75 18.0675 6.75 29.25C6.75 40.4325 15.8175 49.5 27 49.5C38.25 49.5 47.25 40.4325 47.25 29.25C47.25 24.48 45.585 20.0925 42.8175 16.6275ZM29.25 31.5H24.75V15.75H29.25V31.5ZM33.75 2.25H20.25V6.75H33.75V2.25Z" fill="black"></path>
                                            </svg>
                                        </div>
                                        <div className="advantage-item__text">
                                            <p>Срок подачи заявки на возврат по товару — <b>14 дней</b> с момента выписки накладной. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 advantage-col delivery-col">
                                    <div className="advantage-item">
                                        <div className="advantage-item__icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.895 24.2775C18.135 30.645 23.355 35.865 29.7225 39.105L34.6725 34.155C35.3025 33.525 36.18 33.345 36.9675 33.5925C39.4875 34.425 42.1875 34.875 45 34.875C45.5967 34.875 46.169 35.1121 46.591 35.534C47.0129 35.956 47.25 36.5283 47.25 37.125V45C47.25 45.5967 47.0129 46.169 46.591 46.591C46.169 47.0129 45.5967 47.25 45 47.25C34.8555 47.25 25.1264 43.2201 17.9532 36.0468C10.7799 28.8736 6.75 19.1445 6.75 9C6.75 8.40326 6.98705 7.83097 7.40901 7.40901C7.83097 6.98705 8.40326 6.75 9 6.75H16.875C17.4717 6.75 18.044 6.98705 18.466 7.40901C18.8879 7.83097 19.125 8.40326 19.125 9C19.125 11.8125 19.575 14.5125 20.4075 17.0325C20.655 17.82 20.475 18.6975 19.845 19.3275L14.895 24.2775Z" fill="black"></path>
                                            </svg>
                                        </div>
                                        <div className="advantage-item__text">
                                            <p>Оформить возврат можно или позвонив в Call-центр по номеру <b>+7 727 390 91 91</b>. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 advantage-col delivery-col">
                                    <div className="advantage-item">
                                        <div className="advantage-item__icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.75 9C5.55653 9 4.41193 9.47411 3.56802 10.318C2.72411 11.1619 2.25 12.3065 2.25 13.5V38.25H6.75C6.75 40.0402 7.46116 41.7571 8.72703 43.023C9.9929 44.2888 11.7098 45 13.5 45C15.2902 45 17.0071 44.2888 18.273 43.023C19.5388 41.7571 20.25 40.0402 20.25 38.25H33.75C33.75 40.0402 34.4612 41.7571 35.727 43.023C36.9929 44.2888 38.7098 45 40.5 45C42.2902 45 44.0071 44.2888 45.273 43.023C46.5388 41.7571 47.25 40.0402 47.25 38.25H51.75V27L45 18H38.25V9M22.5 13.5L31.5 22.5L22.5 31.5V24.75H9V20.25H22.5M38.25 21.375H43.875L48.3075 27H38.25M13.5 34.875C14.3951 34.875 15.2536 35.2306 15.8865 35.8635C16.5194 36.4964 16.875 37.3549 16.875 38.25C16.875 39.1451 16.5194 40.0035 15.8865 40.6365C15.2536 41.2694 14.3951 41.625 13.5 41.625C12.6049 41.625 11.7464 41.2694 11.1135 40.6365C10.4806 40.0035 10.125 39.1451 10.125 38.25C10.125 37.3549 10.4806 36.4964 11.1135 35.8635C11.7464 35.2306 12.6049 34.875 13.5 34.875ZM40.5 34.875C41.3951 34.875 42.2535 35.2306 42.8865 35.8635C43.5194 36.4964 43.875 37.3549 43.875 38.25C43.875 39.1451 43.5194 40.0035 42.8865 40.6365C42.2535 41.2694 41.3951 41.625 40.5 41.625C39.6049 41.625 38.7465 41.2694 38.1135 40.6365C37.4806 40.0035 37.125 39.1451 37.125 38.25C37.125 37.3549 37.4806 36.4964 38.1135 35.8635C38.7465 35.2306 39.6049 34.875 40.5 34.875Z" fill="black"></path>
                                            </svg>
                                        </div>
                                        <div className="advantage-item__text">
                                            <p>Товар должен быть передан в магазин компании <b>в течение 3-х рабочих дней</b> после оформления заявки на возврат. Для региональных клиентов — <b>в течение 5 рабочих дней</b> без учета времени товара в пути.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 advantage-col delivery-col">
                                    <div className="advantage-item">
                                        <div className="advantage-item__icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.25 20.25H41.625L29.25 7.875V20.25ZM13.5 4.5H31.5L45 18V45C45 46.1935 44.5259 47.3381 43.682 48.182C42.8381 49.0259 41.6935 49.5 40.5 49.5H13.5C11.0025 49.5 9 47.475 9 45V9C9 6.5025 11.0025 4.5 13.5 4.5ZM33.75 40.5V36H13.5V40.5H33.75ZM40.5 31.5V27H13.5V31.5H40.5Z" fill="black"></path>
                                            </svg>
                                        </div>
                                        <div className="advantage-item__text">
                                            <p>Необходимо предоставить документы вместе с товаром:<br /><b>Юридические лица и ИП с печатью</b> - своя возвратная накладная - 2 шт.<br /><b>Физические лица и ИП без печати</b> - копия уд. личности – 1 шт., заявление на возврат – 1 шт.,</p>
                                        </div>
                                    </div>
                                </div>


                            </div>



                            <div className="guarantee">
                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="guarantee__block">

                                            <div className="guarantee__title">Правила приемки товара:</div>
                                            <div className="guarantee__text">

                                                <ul>
                                                    <li>При получении товара, покупатель в присутствии водителя-экспедитора или иного представителя компании "Пятый Элемент", обязан проверить точное соответсвие наименования, артикула и количества фактически поступившего товара, указанного в сопроводительных документах.</li>
                                                    <li>Приемка доставленного товара по количеству должна производиться в единицах измерения, указанных в сопроводительных документах.</li>
                                                    <li>Особое внимание необходимо обращать при приемке следующих групп товаров:
                                                        <ul>
                                                            <li>кузовные детали;</li>
                                                            <li>баспера;</li>
                                                            <li>пластиковые, декоративные изделия;</li>
                                                            <li>сложные электромеханические изделия;</li>
                                                            <li>радиаторы;</li>
                                                            <li>емкости с различными техническими жидкостями;</li>
                                                            <li>автолампы;</li>
                                                            <li>фары.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Претензии по видимым повреждениям товара, некомплектности предъявляются во время получении груза при представителях компании "Пятый Элемент". После приемки товара ответственность за качество товара переходит к Покупателю.</li>
                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="guarantee__block">

                                            <div className="guarantee__title">Товар не может быть принят на склад, если продукция:</div>
                                            <div className="guarantee__text">
                                                <ul>
                                                    <li>Имеет явные признаки установки;</li>
                                                    <li>Имеет следы от грязных рук, смазки;</li>
                                                    <li>Загрязнена песком;</li>
                                                    <li>Повреждена в результате небрежного обращения;</li>
                                                    <li>Имеются следы закручивания болтов;</li>
                                                    <li>Имеются следы от присоединения фишек (для электропродукции);</li>
                                                    <li>Имеется запах бензина или дизельного топлива;</li>
                                                    <li>Заправлена смазкой (шрусы) кроме продукции, которая заправлена смазкой на заводе-изготовителе.</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="guarantee" style={{ marginTop: 20 }}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="guarantee__block">

                                            <div className="guarantee__title">Возврату и обмену не подлежат:</div>
                                            <div className="guarantee__text">
                                                <ul>
                                                    <li>Масла и смазочные материалы;</li>
                                                    <li>Автохимия - без пломбы;</li>
                                                    <li>Автомобильные лампочки, комплекты ксенона.</li>
                                                </ul>
                                                <p>Компания (продавец) не несет ответственность за неправильный подбор товара покупателем, а так же совместимость запчастей с транспортным средством, в связи с чем возвраты по указанным причинам не принимаются. Возврат товара по причине "не понадобилось" не производится. Спорные вопросы качества товара решаются путем привличения независимой экспертизы.</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="guarantee__block">

                                            <div className="guarantee__title">Товар не может быть принят на склад, если упаковка:</div>
                                            <div className="guarantee__text">
                                                <ul>
                                                    <li>Небрежно разорвана (целостность упаковки нарушена);</li>
                                                    <li>Имеет следы от замасленных пальцев, измазана смазкой;</li>
                                                    <li>Имеет надписи маркером (кроме пометок склада) или ручкой;</li>
                                                    <li>Имеет наклейки с ценой, которые невозможно удалить без повреждения целостности упаковки;</li>
                                                    <li>Замочена водой либо иными жидкостями;</li>
                                                    <li>Нарушена целостность упаковки комплектов вкладышей двигателя, клапанов двигателя, комплектов поршневых колец (данные позиции возвращаются только в полностью герметичной упаковке).</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
