import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

class Meta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meta: null,
        };
    }

    async fetchMeta() {
        try {
            const response = await fetch(`/api/meta`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location: window.location.pathname }),
            });

            if (!response.ok) {
                throw new Error('Ошибка загрузки данных в сети');
            }

            const meta = await response.json();

            this.setState({ meta });
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        }
    }

    async componentDidMount() {
        await this.fetchMeta();
    }

    render() {
        const { meta } = this.state;

        return (
            <MetaTags>
                {meta && Object.entries(meta).map(([key, value]) => (
                    <meta key={key} property={key} content={value} />
                ))}
            </MetaTags>
        );
    }
}

export default Meta;
