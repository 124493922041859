import React, { Component } from 'react';

class Breadcrumb extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: this.props.values
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.values !== this.props.values ) {
            this.setState({
                values: this.props.values,
            });
        }
    }
    
    render() {
        const {values} = this.state;

        return (
            <ul className="breadcrumb">
                {Array.from(values.entries()).map(([key, value], index, array) => (
                    <li className="breadcrumb-item" key={key}>
                        {index === array.length - 1 ? (
                            value
                        ) : (
                            <a href={key}>{value}</a> 
                        )}
                    </li>
                ))}
            </ul>
        )
    }
}

export default Breadcrumb;