import React, { Component } from 'react';
import InputMask from 'react-input-mask';

class Telphone extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tel: this.props.tel
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tel !== this.props.tel) {
            this.setState({
                tel: this.props.tel,
            });
        }
    }

    onChange = (e) => {
        this.setState({ tel: e.target.value });
        this.props.onChange(e);
    };

    render() {
        const { tel } = this.state;

        return (
            <InputMask
                mask="+7 (999) 999-99-99"
                value={tel}
                onChange={this.onChange}
                required
                className="form-control"
                placeholder="Телефон"
            >
                {(inputProps) => <input {...inputProps} type="text" name="customer_telephone" />}
            </InputMask>
        )
    }
}

export default Telphone;