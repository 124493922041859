import React, { Component } from 'react';

import Search from './Search';

class HomeBanner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            search: {
                year: 0,
                value: "",
                model: "",
                manufacture: "",
            },
            category: [],
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    async getCategory() {
        const response = await fetch('/api/category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            return [];
        }

        return await response.json();
    }

    async componentDidMount() {
        const category = await this.getCategory();

        this.setState({
            category
        })
    }

    handleButtonClick(uuid) {
        window.location.href = `./catalog/` + uuid;
    }
    
    renderCategoryItems() {
        const { category } = this.state

        return category.map((item) => (
            <div key={item.uuid} className="col-lg col-md-4 col-sm-6 col-6">
                <div className="prodsubcat-item" key={item.uuid} style={{ textAlign: 'center' }}>
                    <button style={{border: "0"}} className="prodsubcat-item" onClick={() => this.handleButtonClick(item.uuid)}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <img height="40px" src={"./images/icons/" + item.img} alt={item.name} />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                {item.name}
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        ));
    }

    handleSearch = async (value, manufacture, model, year) => {
        this.setState({
            search: {
                year: year,
                value: value,
                model: model,
                manufacture: manufacture,
            }
        });
    };

    handleEdit = async () => {
        const { search } = this.state;
        
        window.location.href = `./catalog?search=${encodeURIComponent(JSON.stringify(search))}`;
    };

    render() {
        const { category } = this.state

        return (
            <div className="section-home-banner">
                <div className="section-bg">
                    <img src="./images/home-banner.jpg" alt="баннер" />
                </div>
                <div className="container">
                    <div className="container-banner">

                        <h1 className="home-banner__title">
                            <big>качественные</big><br />&nbsp;запчасти&nbsp;для&nbsp;<strong>вашего&nbsp;авто</strong>
                        </h1>

                    </div>
                </div>
                <div className="container">
                    <div className="search-bar__block">
                        <div className="row">
                            <div className="col-sm-12">
                                <Search
                                    onEdit={this.handleEdit}
                                    onSearch={this.handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-prodsubcat">
                    <div className="container">
                        <div className="section-title">
                            Дополнительные товары
                        </div>

                        <div className="my-auto">
                            {category && category.length > 0 &&
                                <div className="row justify-content-center">
                                    {this.renderCategoryItems()}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="section-line">
                    <svg width="2000" height="87" viewBox="0 0 2000 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2000 44.5189L1696.96 44.5188L1622.29 2.00028L1280.96 2.00022L1142.29 84.9632L438.16 84.963L309.28 14.573L-1.93027e-06 14.573" stroke="#0575C0" strokeWidth="3" strokeMiterlimit="10"></path>
                    </svg>
                </div>
            </div>
        )
    }
}

export default HomeBanner;