import React from 'react'

import FooterLogo from './FooterLogo';

import ColumnLeft from './FooterMenu/ColumnLeft';
import ColumnCenter from './FooterMenu/ColumnCenter';
import ColumnRight from './FooterMenu/ColumnRight';

export default function Footer() {
    return (
        <footer>

            <div className="section-line">
                <svg width="2000" height="87" viewBox="0 0 2000 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2000 44.5189L1696.96 44.5188L1622.29 2.00028L1280.96 2.00022L1142.29 84.9632L438.16 84.963L309.28 14.573L-1.93027e-06 14.573" stroke="#0575C0" strokeWidth="3" strokeMiterlimit="10"></path>
                </svg>
            </div>

            <div className="section-line-bg">
                <svg width="2000" height="93" viewBox="0 0 2000 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M301.199 15L-2.62267e-06 14.9999L1.37405e-05 92.9995L2000 93L2000 36.0001L1685.25 36.0001L1619.59 0.000283179L1292.34 0.00022596L1146.43 81.9997L422.095 81.9996L301.199 15Z" fill="#000000"></path>
                </svg>
            </div>

            <div className="container">
                <div className="footer-row">

                    <FooterLogo />

                    <div className="footer-right">

                        <ColumnLeft />
                        <ColumnCenter />
                        <ColumnRight />

                    </div>

                </div>
            </div>

        </footer>
    )
}
