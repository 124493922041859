import React from 'react'

import Breadcrumb from '../../header/Breadcrumb'

export default function Stock() {
    return (
        <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, minHeight: 600,  boxSizing: "border-box" }}>
            <div className="container">

                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Акции"], 
                ])} />

                <h1 className="page-content__title section-title">
                    Акции
                </h1>

                <div className="page-content__description">

                    <div className="page-content__row">
                        <div className="row">


                            <div className="col-lg-4  col-md-6 col-sm-6 stock-item__col">
                                <div className="news-item stock-item">
                                    <div className="news-item__img stock-item__img">
                                        <img src="./images/act.jpg" alt="" title="" />
                                    </div>
                                    <div className="stock-item__content">
                                        <div className="stock-item__title">
                                            Приятности от Пятого Элемента не заканчиваются
                                        </div>
                                    </div>
                                    <a href="/#" className="news-item__link md-trigger" data-modal="article_5">Подробнее</a>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="page-content__pagination">

                    </div>
                </div>
            </div>
        </div>
    )
}
