import React, { Component } from 'react';

class ColumnCenter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: new Map()
        };
    }

    componentDidMount() {
        this.setState({
            values: new Map([
                ["/sotrudnichestvo", "Сотрудничество"],
                ["/actii", "Акции"],
                ["/o-nas", "О нас"],
                ["/news", "Новости"],
                ["/kak-kupit-v-internet-magazine-feparts-kz", "Как купить?"],
            ])
        })
    }

    render() {
        return (
            <div className="footer-menu-col">
                <nav>
                    
                    <ul className="menu">

                        {Array.from(this.state.values.entries()).map(([key, value]) => (

                            <li key={key}><a className="" href={key}>{value}</a></li>

                        ))}

                    </ul>
            
                </nav>
            </div>
        )
    }
}


export default ColumnCenter;