import React, { Component } from 'react'

import ItemNews from './ItemNews'
import Breadcrumb from '../../header/Breadcrumb'

class News extends Component {

    constructor(props) {
        super(props);

        this.state = {
            news: []
        };
    }

    async getNews() {
        const response = await fetch('/api/news', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            return [];
        }

        return await response.json();
    }

    async componentDidMount() {
        const news = await this.getNews();

        this.setState({
            news
        })
    }

    render() {
        const { news } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, minHeight: 600, boxSizing: "border-box" }}>
                <div className="container">

                    <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "Новости"],
                    ])} />

                    <h1 className="page-content__title section-title">
                        Новости
                    </h1>

                    <div className="container">
                        <div className="page-content__description">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="page-content__row">
                                        {news && news.length > 0 &&
                                            <div className="row">
                                                {news.map((item) => (
                                                    <ItemNews
                                                        key={item.id}
                                                        id={item.id}
                                                        img={item.img}
                                                        date={item.date}
                                                        text={item.text}
                                                        title={item.title}
                                                    />
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

export default News;