import React, { Component } from 'react';

import Modal from 'react-modal';

import User from "./../utils/User";
import Token from "./../utils/Token";

import UserType from './../enum/UserType';

class ModalWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sms: "",
            type: this.props.type,
            value: this.props.value,
            redirect: this.props.redirect,
            redirectUrl: this.props.redirectUrl,
            modalIsOpen: true,
        };

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        const { redirect, redirectUrl } = this.state;

        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }

        if (redirect) {
            window.location.href = redirectUrl;
        }
    }

    handleLogin = async (event) => {
        const { sms } = this.state;

        try {
            const response = await fetch('/api/register/sms/' + sms, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            const login = await response.json();

            this.setState({ value: login.message, type: login.type });

            if (login.status === "OK") {
                (new User()).update(login.user.fullName);
                (new User()).updateType(login.type);
                (new Token()).update(login.user.token);

                this.setState({ modalIsOpen: false, redirect: true, redirectUrl: "/" })

                setTimeout(() => {
                    this.setState({ modalIsOpen: false, redirect: true, redirectUrl: "/" }, () => {
                        this.closeModal();
                    });
                }, 500);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    render() {
        const { value, type, sms, modalIsOpen } = this.state;

        let enter = false;
        
        if (type == UserType.RETAIL) {
            enter = value == "Пользователь не активирован, перейдите по ссылке в отправленном вам письме или введите код из смс." ? true : false;
        }

        if (type == UserType.WHOLESALE) {
            if (value == "Пользователь не активирован, перейдите по ссылке в отправленном вам письме или введите код из смс.") {
                this.setState({ value: "Пользователь не активирован" });
            }
        }

        return (
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.customStyles}
            >
                <div className="modal-content" style={{ border: 0 }}>
                    <br /><p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: value }}></p>
                    {enter &&
                        <>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: "inline-block", width: "100%", textAlign: 'center' }}>
                                    <form onSubmit={this.handleLogin} style={{ border: 0, display: 'inline-block' }}>
                                        <input
                                            type="text"
                                            value={sms}
                                            placeholder="Код"
                                            onChange={(e) => this.setState({ sms: e.target.value })}
                                            style={{ maxHeight: 25, minHeight: 25, height: '100%' }}
                                        />
                                        <input
                                            type="submit"
                                            value="OK"
                                            onClick={this.codeHandle}
                                            style={{ maxHeight: 25, minHeight: 25, width: 50, marginLeft: '10px' }}
                                        />
                                    </form>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </>
                    }
                    <button onClick={this.closeModal} className="btn btn-primary">Закрыть</button>
                </div>
            </Modal>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '100%',
            maxWidth: 500,
            transform: 'translate(-50%, -50%)',
        },
    };
}

export default ModalWindow;
