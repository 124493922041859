import React, { Component } from 'react';

class Download extends Component {
  constructor(props) {
    super(props);

    this.download = this.download.bind(this);
  }

  download() {
    const { data } = this.props;

    const json = JSON.stringify(data, null, 2);

    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Заказ №' + data.number + '.json';
    link.click();

    URL.revokeObjectURL(url);
  }

  render() {
    return (<button className="btn btn-primary" onClick={this.download}>Скачать (JSON)</button>);
  }
}

export default Download;
