import React, { Component } from 'react';
import Token from "./../../utils/Token";
import EventBus from "./../../utils/EventBus";

class HeaderCart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 0,
        }

        this.updateCount = this.updateCount.bind(this);
    }

    async getCount() {
        try {
            const response = await fetch('/api/cart/count', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                }
            });

            if (!response.ok) {
                return { count: 0 };
            }

            return await response.json();
        } catch {
            return { count: 0 };
        }
    }

    async updateCount() {
        const data = await this.getCount();

        this.setState({
            count: data.count,
        });
    }

    componentDidMount() {
        this.updateCount();

        EventBus.on('cartUpdated', this.updateCount);
    }

    componentWillUnmount() {
        EventBus.off('cartUpdated', this.updateCount);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.count !== this.props.count) {
            this.setState({ count: this.props.count });
        }
    }

    render() {
        const { count } = this.state;

        return (
            <div className="header-card">
                <div>
                    <a href="/cart" className="header-button">
                        <i className="header-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 6H17C17 3.2 14.8 1 12 1C9.2 1 7 3.2 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.7 3 15 4.3 15 6H9C9 4.3 10.3 3 12 3ZM19 20H5V8H19V20ZM12 12C10.3 12 9 10.7 9 9H7C7 11.8 9.2 14 12 14C14.8 14 17 11.8 17 9H15C15 10.7 13.7 12 12 12Z" fill="#EDEBE8"></path>
                            </svg>
                            {count > 0 && 
                                <span className="card-number">{count}</span>
                            }
                        </i>
                        <span>Корзина</span>
                    </a>
                </div>
            </div>
        )
    }
}

export default HeaderCart;
