import React, { useState, useEffect } from 'react';

const CategoryMenu = ({ groups }) => {

    const [visibleCategories, setVisibleCategories] = useState(() => {
        const savedState = localStorage.getItem('visibleCategories');
        return savedState ? JSON.parse(savedState) : {};
    });

    useEffect(() => {
        localStorage.setItem('visibleCategories', JSON.stringify(visibleCategories));
    }, [visibleCategories]);

    const toggleCategoryVisibility = (groupUuid) => {
        setVisibleCategories(prevState => ({
            ...prevState,
            [groupUuid]: !prevState[groupUuid]
        }));
    };

    return (
        <div className="category-manu list-group" style={{marginLeft: -100}}>
            <ul>
                {groups.map(group => (
                    group.count > 0 && (
                        <li className="category-parent" key={group.uuid} style={{boxShadow: "none"}}>
                            <div className="list-group-item" style={{boxShadow: "none"}}>
                                <div style={{display: 'flex', maxWidth: 270}}>
                                    <div style={{minWidth: 270, maxWidth: 270}}>
                                        <a href={"/catalog/" + group.uuid} className="item-link" style={{boxShadow: "none"}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 250}}>
                                                <div style={{minWidth: 230, maxWidth: 230, textAlign: 'left'}}>
                                                    { group.name }
                                                </div>
                                                <div style={{ paddingLeft: 30, minWidth: 10, maxWidth: 10, textAlign: 'right'}}>
                                                    {'(' + group.count + ')'}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div style={{minWidth: 10, maxWidth: 10}}>
                                        {group.child.length > 0 && (
                                            <div style={{padding: 20, paddingLeft: 45, minWidth: 10, maxWidth: 10, textAlign: 'right'}}>
                                                <span className="category-click" onClick={() => toggleCategoryVisibility(group.uuid)}>
                                                    <svg width="14" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: visibleCategories[group.uuid] ? "rotate(180deg)" : "rotate(0deg)" }}>
                                                        <path d="M22 2L12 12L2 2" stroke="#0575C0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {group.child.length > 0 && (
                                <ul className="category-children" style={{marginLeft: 20,  display: visibleCategories[group.uuid] ? "block" : "none", transition: "all 0.5s eas" }}>
                                    {group.child.map(child => (
                                        child.count > 0 && (
                                            <li key={child.uuid}>
                                                <a href={"/catalog/" + child.uuid} className="list-group-item">
                                                    <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 270}}>
                                                        <div style={{minWidth: 250, maxWidth: 250, textAlign: 'left'}}>
                                                            { child.name }
                                                        </div>
                                                        <div style={{paddingLeft: 25, minWidth: 20, maxWidth: 20, textAlign: 'right'}}>
                                                            {'(' + child.count + ')'}
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            )}
                        </li>
                    )
                ))}
            </ul>
        </div>
    );
};

export default CategoryMenu;
