import React, { Component } from 'react';

import Carousel from "./../../utils/Carousel";

class SectionBrends extends Component {
    
    path = "./images/brend/";

    constructor(props) {
        super(props);

        this.state = {
            values: [
                {
                    "id": 1,
                    "href": "/manufacture/carstation",
                    "img": this.path + "carstation.png",
                    "name": "carstation",
                },
                {
                    "id": 2,
                    "href": "/manufacture/central",
                    "img": this.path + "central.png",
                    "name": "central",
                },
                {
                    "id": 3,
                    "href": "/manufacture/ctr",
                    "img": this.path + "ctr.png",
                    "name": "ctr",
                },
                {
                    "id": 4,
                    "href": "/manufacture/nap",
                    "img": this.path + "nap.png",
                    "name": "nap",
                },
                {
                    "id": 5,
                    "href": "/manufacture/rheejin",
                    "img": this.path + "rheejin.png",
                    "name": "rheejin",
                },
                {
                    "id": 6,
                    "href": "/manufacture/shinhwa",
                    "img": this.path + "shinhwa.png",
                    "name": "shinhwa",
                },
                {
                    "id": 7,
                    "href": "/manufacture/yuilfilter",
                    "img": this.path + "yuilfilter.png",
                    "name": "yuilfilter",
                }
            ]
        };
    }

    render() {
        const { values } = this.state;

        return (
            <div className="section section-brends">
                <div className="container">

                    <div className="section-title">
                        сотрудничаем<br />
                        <em>с производителями</em>
                    </div>

                    <div className="brends-slider__wrapper">
                        <Carousel values={values} index={0}/>
                        <Carousel values={values} index={1}/>
                        <Carousel values={values} index={2}/>
                        <Carousel values={values} index={3}/>
                    </div>

                    <div className="news__footer">
                        {/*
                        <a href="/manufacture" className="news__all" style={{ background: "#333", color: "#fff", border: 0 }}>Смотреть все</a>
                        */}
                    </div>

                </div>
            </div>
        )
    }
}

export default SectionBrends;