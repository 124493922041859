import React from 'react'

import Breadcrumb from '../../header/Breadcrumb'

export default function About() {
    return (
        <div className="page-content" style={{padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
            <div className="container">

                <Breadcrumb values={new Map([
                        ["/", "Главная"],
                        ["/#", "О нас"], 
                    ])} />

                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="page-content__title section-title">О&nbsp;нас</h1>
                        <div className="page-content__description">

                            <div className="row">

                                <div className="col-sm-7">
                                    <img src="/images/1.jpg" alt="test" title="test" style={{ width: "100%" }} />
                                </div>
                                <div className="col-sm-5">
                                    <p>Компания “Пятый элемент” - это большой магазин оптовой и розничной торговли с удобным расположением в центре города Алматы и парковкой для клиентов.</p>
                                    <p>Мы разработали собственную систему логистики, позволяющую осуществлять прямые поставки из Южной Кореи и Тайваня на регулярной основе.</p>
                                    <p>Сотрудничаем со многими автосервисами города Алматы, а так же в регионах Казахстана.</p>
                                    <p>Помогаем грамотно подобрать запчасти и своевременно их доставить, при этом даём гарантию на заводской брак.</p>
                                </div>

                            </div>
                            <div style={{ marginTop: 80 }}>
                                <img src="/images/Group_33988.png" style={{ width: "100%" }} alt=""/>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>)
}
