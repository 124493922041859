import React from 'react'

export default function FooterLogo() {
    return (
        <div className="footer-logo">
            <a href="https://feparts.kz/">
                <img src="./../images/footer-logo-new.png" alt="КАЧЕСТВЕННЫЕ ЗАПЧАСТИ ДЛЯ ВАШЕГО АВТО" />
            </a>
        </div>
    )
}
