import React, { Component } from 'react';
import Modal from 'react-modal';

class ModalText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: "",
            title: this.props.title,
            fileName: this.props.fileName,
            modalIsOpen: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.fetchFileContent();
    }

    async fetchFileContent() {
        try {
            const response = await fetch('/doc/' + this.state.fileName);
            const data = await response.text();
            this.setState({ text: data });
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    }

    openModal() {
        this.setState({ modalIsOpen: true });

    }

    closeModal() {
        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const { text, title, modalIsOpen } = this.state;

        return (
            <>
                <span style={{ cursor: 'pointer' }} className="md-trigger" onClick={this.openModal}>{title}</span>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={this.customStyles}
                >
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </Modal>
            </>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginTop: '50px',
            marginRight: '-50%',
            width: "90vw",
            height: "80vh",
            transform: 'translate(-50%, -50%)',
        },
    };
}

export default ModalText;
