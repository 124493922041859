import React, { Component } from 'react';
import Modal from 'react-modal';

class ModalThanks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: true,
        };

        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
    }

    closeModal() {
        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    afterOpenModal() {
        if (this.subtitle) {
            this.subtitle.style.color = '#f00';
        }
    }

    render() {
        const { modalIsOpen } = this.state;

        return (
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.customStyles}
                contentLabel=""
            >
                <div className="modal-content" style={{textAlign: 'center'}}>
                    <h2>Спасибо!</h2>
                    <p>Мы с вами свяжемся с вами как можно скорее.</p>
                    <button onClick={this.closeModal} className="btn btn-primary">Закрыть</button>
                </div>
            </Modal>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '100%',
            maxWidth: 500,
            transform: 'translate(-50%, -50%)',
        },
    };
}

export default ModalThanks;
