import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Index from './components/pages/index/Index';
import Review from './components/pages/review/Review';
import Stock from './components/pages/stock/Stock';
import Garantiya from './components/pages/garantiya/Garantiya';
import Dostavka from './components/pages/dostavka/Dostavka';
import Oplata from './components/pages/oplata/Oplata';
import Sotrudnichestvo from './components/pages/sotrudnichestvo/Sotrudnichestvo';
import About from './components/pages/about/About';
import News from './components/pages/news/News';
import HowToBuy from './components/pages/howToBuy/HowToBuy';
import Catalog from './components/pages/catalog/Catalog';
import Product from './components/pages/catalog/Product';
import Cart from './components/pages/cart/Cart';
import Checkout from './components/pages/cart/Checkout';
import Payment from './components/payment/Payment';
import Login from './components/pages/login/Login';
import Retail from './components/pages/register/Retail';
import Wholesale from './components/pages/register/Wholesale';
import Dealer from './components/pages/dealer/Dealer';
import Contact from './components/pages/contact/Contact';
import AccountRetail from './components/pages/account/AccountRetail';
import AccountWholesale from './components/pages/account/AccountWholesale';
import Forgotten from './components/pages/forgotten/Forgotten';
import NotFoundPage from './components/pages/NotFoundPage';
import Email from './components/pages/Email';
import SMS from './components/pages/SMS';
import Document from './components/pages/document/Document';
import Error from './components/pages/Error';
import Service from './components/pages/Service';

import Mobile from "./components/utils/Mobile";
import DeviceDetect from "./components/utils/DeviceDetect";
import ModeService from "./components/utils/ModeService";
import Token from './components/utils/Token';
import Visit from './components/utils/Visit';
import Discount from './components/utils/Discount';
import User from './components/utils/User';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //ws: null,
      isService: false,
      isMobile: false,
    };

    (new Visit()).check();
  }

  async componentDidMount() {
    const token = (new URLSearchParams(window.location.search)).get('token');

    if (token && token.trim() !== "") {
      const response = await fetch('/api/login/' + token, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
          const login = await response.json();
      
          (new User()).update(login.fullName);
          (new User()).updateType(login.type);
          (new Token()).update(login.token);

          window.location.href = '/';
      }
    }

    (new Discount());

    const isService = await ModeService();
    const isMobile = DeviceDetect();

    this.setState({ isService, isMobile });

    if (!isService) {
      /*
      const wsConnection = new WebSocket(`ws://${window.location.hostname}:8080/ws`);

      wsConnection.onopen = () => {
        wsConnection.send(new Token().get());
      };

      wsConnection.onmessage = () => {
        wsConnection.send(new Token().get());
      };

      wsConnection.onclose = () => { };

      this.setState({ ws: wsConnection });
      */
    }
  }

  componentWillUnmount() {
    /*
    const { ws } = this.state;
    if (ws) {
      ws.close();
    }
    */
  }

  renderAccountRoute() {
    const userType = new User().getType();
    switch (userType) {
      case "RETAIL":
        return <Route path="/account" component={AccountRetail} />;
      case "WHOLESALE":
        return <Route path="/account" component={AccountWholesale} />;
      default:
        return <Route path="/account" component={Index} />;
    }
  }

  render() {
    const { isService, isMobile } = this.state;

    if (isService) {
      return (
        <Router>
          <Switch>
            <Route exact path="/" component={Service} />
          </Switch>
        </Router>
      );
    }

    if (isMobile) {
      window.location.href = "https://mobile.fe.kz";
    }
    
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Index} isService={isService} />
          <Route path="/cart" component={Cart} />
          {this.renderAccountRoute()}
          <Route path="/login" component={Login} />
          <Route path="/dealer" component={Dealer} />
          <Route path="/contact" component={Contact} />
          <Route path="/register/retail" component={Retail} />
          <Route path="/register/wholesale" component={Wholesale} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/payment" component={Payment} />
          <Route path="/reviews" component={Review} />
          <Route path="/stock" component={Stock} />
          <Route path="/garantiya" component={Garantiya} />
          <Route path="/dostavka" component={Dostavka} />
          <Route path="/oplata" component={Oplata} />
          <Route path="/sotrudnichestvo" component={Sotrudnichestvo} />
          <Route path="/about" component={About} />
          <Route path="/news" component={News} />
          <Route path="/howToBuy" component={HowToBuy} />
          <Route path="/forgotten" component={Forgotten} />
          <Route path="/catalog/product/:id" render={({ match }) => (
            <Redirect to={`/product/${match.params.id}`} />
          )} />
          <Route path="/catalog/:id?" component={Catalog} />
          <Route path="/product/:id?" component={Product} />
          <Route path="/register/sms/:id?" component={SMS} />
          <Route path="/register/email/:id?" component={Email} />
          <Route path="/document/:id?" component={Document} />
          <Route path="/product" component={Product} />
          <Route path="/error" component={Error} />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;