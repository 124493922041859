import { v4 as uuidv4 } from 'uuid';

class Token {
    constructor() {
        if (localStorage.getItem('token') === null) {
            this.update(uuidv4());
        }
    }

    get() {
        return localStorage.getItem('token');
    }

    update(token) {
        localStorage.setItem('token', token);
    }

    delete() {
        localStorage.removeItem('token');
    }
}

export default Token;
